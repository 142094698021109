import { useState } from "react";
import { TabItem, TabList } from "../../../../../bidding/common/tab-list";
import { TabContent } from "../../../../../amrPipeline/common/tabs/TabContent";
import { PortfolioUserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";
import { PortfolioFiltersAlertsTable } from "./PortfolioFilterAlertsTable";
import {
    AlertOption,
    instantAlertOptions,
    periodicAlertOption,
} from "../../../../../../types/email-preferences/EmailPreferences";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { SubscriptionFeature } from "../../../../../../types/billing/SubscriptionFeature";
import { user } from "../../../../../../user";
import { textLabels } from "../../../../../../constants/text-labels";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";
import { portfolioImRequiredFeatures } from "./PortfolioTab";

type OnChangeHandler = (alertOption: AlertOption) => void;

interface Props {
    cnAlert: AlertOption;
    cnState?: AlertEnabledState;
    onChangeCnAlert: OnChangeHandler;
    imAlert: AlertOption;
    imState?: AlertEnabledState;
    onChangeImAlert: OnChangeHandler;
    outOfRiAlert: AlertOption;
    outOfRiState?: AlertEnabledState;
    onChangeOutOfRiAlert: OnChangeHandler;
    outOfNcAlert: AlertOption;
    outOfNcState?: AlertEnabledState;
    onChangeOutOfNcAlert: OnChangeHandler;
    rollerDeadlineAlert: AlertOption;
    rollerDeadlineState?: AlertEnabledState;
    onChangeRollerDeadlineAlert: OnChangeHandler;
    bwicAlert: AlertOption;
    bwicState?: AlertEnabledState;
    onChangeBwicAlert: OnChangeHandler;
    inventoryAlert: AlertOption;
    inventoryState?: AlertEnabledState;
    onChangeInventoryAlert: OnChangeHandler;
    portfolioFilters?: PortfolioUserConfigFilter[];
    isFilterTableDisabled?: boolean;
}

const convertToCount = (option: AlertOption) =>
    option === AlertOption.Never ? 0 : 1;

const renderCounter = (optionsCount: number[]) => {
    const selectedOptions = optionsCount.reduce(
        (acc, option) => acc + option,
        0
    );

    return (
        <span className="text-regular text-warm-grey">
            ({selectedOptions}/{optionsCount.length})
        </span>
    );
};

export function PortfolioAlertsTabs({
    cnAlert,
    cnState,
    onChangeCnAlert,
    bwicAlert,
    bwicState,
    onChangeBwicAlert,
    inventoryAlert,
    inventoryState,
    onChangeInventoryAlert,
    imAlert,
    imState,
    onChangeImAlert,
    outOfRiAlert,
    outOfRiState,
    onChangeOutOfRiAlert,
    outOfNcAlert,
    outOfNcState,
    onChangeOutOfNcAlert,
    rollerDeadlineAlert,
    rollerDeadlineState,
    onChangeRollerDeadlineAlert,
    portfolioFilters,
    isFilterTableDisabled,
}: Props) {
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);

    const portfolioAlertsAccess = user.hasFeatures(SubscriptionFeature.PortfolioAlerts);

    const portfolioImAlertsAccess = user.hasAllFeatures(
        ...[
            SubscriptionFeature.IssuanceMonitorFullAccess,
            SubscriptionFeature.PortfolioAlerts,
        ]
    );
    const [isPrimary, setIsPrimary] = useState(true);

    const savedFiltersBwicAlertOption = portfolioFilters?.some((p) => {
        const alert = filtersAlertState[p.referenceName]?.bwicAlert;
        return alert && alert !== AlertOption.Never;
    })
        ? AlertOption.Instant
        : AlertOption.Never;

    const savedFiltersInventoryAlertOption = portfolioFilters?.some((p) => {
        const alert = filtersAlertState[p.referenceName]?.inventoryAlert;
        return alert && alert !== AlertOption.Never;
    })
        ? AlertOption.Instant
        : AlertOption.Never;

    const primaryAlerts = [
        portfolioImAlertsAccess ? convertToCount(cnAlert) : 0,
        portfolioImAlertsAccess ? convertToCount(imAlert) : 0,
        portfolioImAlertsAccess ? convertToCount(outOfRiAlert) : 0,
        portfolioImAlertsAccess ? convertToCount(outOfNcAlert) : 0,
        portfolioImAlertsAccess ? convertToCount(rollerDeadlineAlert) : 0,
    ];

    const secondaryAlerts = [
        convertToCount(bwicAlert),
        convertToCount(inventoryAlert),
        ...(portfolioFilters?.length && portfolioAlertsAccess
            ? [convertToCount(savedFiltersBwicAlertOption)]
            : []),
        ...(portfolioFilters?.length && portfolioAlertsAccess
            ? [convertToCount(savedFiltersInventoryAlertOption)]
            : []),
    ];

    return (
        <>
            <TabList>
                <TabItem
                    active={isPrimary}
                    title={<>Primary {renderCounter(primaryAlerts)}</>}
                    onClick={() => setIsPrimary(true)}
                />
                <TabItem
                    active={!isPrimary}
                    title={<>Secondary {renderCounter(secondaryAlerts)}</>}
                    onClick={() => setIsPrimary(false)}
                />
            </TabList>
            <TabContent>
                {isPrimary ? (
                    <>
                        <EmailPreferencesRadioGroup
                            value={cnAlert}
                            alertOptions={instantAlertOptions}
                            alertOptionInfo={{
                                [AlertOption.Instant]:
                                    textLabels.savedFiltersPortfolioCleansingNoticeInstantAlert,
                            }}
                            groupNameIndentifier="single-portfolio-cleansing-notice-alert-state"
                            title="Cleansing Notice"
                            description={
                                textLabels.savedFiltersPortfolioCleansingNoticeAlertDescription
                            }
                            onChange={onChangeCnAlert}
                            partially={
                                cnState ===
                                AlertEnabledState.PartiallyEnabled
                            }
                            requiredFeatures={portfolioImRequiredFeatures}
                        />
                        <EmailPreferencesRadioGroup
                            value={imAlert}
                            alertOptions={instantAlertOptions}
                            alertOptionInfo={{
                                [AlertOption.Instant]:
                                    textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                            }}
                            groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                            title="Issuance Monitor"
                            description={
                                textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription
                            }
                            onChange={onChangeImAlert}
                            partially={
                                imState === AlertEnabledState.PartiallyEnabled
                            }
                            requiredFeatures={portfolioImRequiredFeatures}
                        />
                        <EmailPreferencesRadioGroup
                            value={outOfRiAlert}
                            alertOptions={periodicAlertOption}
                            alertOptionInfo={{
                                [AlertOption.Periodic]:
                                    textLabels.savedFiltersPortfolioOutOfRiPeriodicAlert,
                            }}
                            groupNameIndentifier="single-portfolio-out-of-ri-alert-state"
                            title="Out of RI Period"
                            description={
                                textLabels.savedFiltersPortfolioOutOfRiAlertDescription
                            }
                            onChange={onChangeOutOfRiAlert}
                            partially={
                                outOfRiState ===
                                AlertEnabledState.PartiallyEnabled
                            }
                            requiredFeatures={portfolioImRequiredFeatures}
                        />
                        <EmailPreferencesRadioGroup
                            value={outOfNcAlert}
                            alertOptions={periodicAlertOption}
                            alertOptionInfo={{
                                [AlertOption.Periodic]:
                                    textLabels.savedFiltersPortfolioOutOfNcPeriodicAlert,
                            }}
                            groupNameIndentifier="single-portfolio-out-of-nc-alert-state"
                            title="Out of NC Period"
                            description={
                                textLabels.savedFiltersPortfolioOutOfNcAlertDescription
                            }
                            onChange={onChangeOutOfNcAlert}
                            partially={
                                outOfNcState ===
                                AlertEnabledState.PartiallyEnabled
                            }
                            requiredFeatures={portfolioImRequiredFeatures}
                        />
                        <EmailPreferencesRadioGroup
                            value={rollerDeadlineAlert}
                            alertOptions={instantAlertOptions}
                            alertOptionInfo={{
                                [AlertOption.Instant]:
                                    textLabels.savedFiltersPortfolioRollerDeadlineInstantAlert,
                            }}
                            groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                            title="Roller Deadline"
                            description={
                                textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription
                            }
                            onChange={onChangeRollerDeadlineAlert}
                            partially={
                                rollerDeadlineState ===
                                AlertEnabledState.PartiallyEnabled
                            }
                            requiredFeatures={portfolioImRequiredFeatures}
                        />
                    </>
                ) : (
                    <>
                        <EmailPreferencesRadioGroup
                            value={bwicAlert}
                            alertOptions={instantAlertOptions}
                            alertOptionInfo={{
                                [AlertOption.Instant]:
                                    textLabels.savedFiltersPortfolioBwicInstantAlert,
                            }}
                            groupNameIndentifier="single-portfolio-bwicAlert-alert-state"
                            title="BWIC Alerts"
                            description={
                                textLabels.savedFiltersPortfolioBwicAlertDescription
                            }
                            onChange={onChangeBwicAlert}
                            partially={
                                bwicState === AlertEnabledState.PartiallyEnabled
                            }
                        />
                        <EmailPreferencesRadioGroup
                            value={inventoryAlert}
                            alertOptions={instantAlertOptions}
                            alertOptionInfo={{
                                [AlertOption.Instant]:
                                    textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                            }}
                            groupNameIndentifier="single-portfolio-inventoryAlert-alert-state"
                            title="Inventory Alerts"
                            description={
                                textLabels.savedFiltersPortfolioInventoryAlertDescription
                            }
                            onChange={onChangeInventoryAlert}
                            partially={
                                inventoryState ===
                                AlertEnabledState.PartiallyEnabled
                            }
                        />
                        {!!portfolioFilters && (
                            <PortfolioFiltersAlertsTable
                                disabled={isFilterTableDisabled}
                                savedFilters={portfolioFilters}
                            />
                        )}
                    </>
                )}
            </TabContent>
        </>
    );
}

