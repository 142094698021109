import { DealSecurity } from '../../security/DealSecurityStatistics';
import { CollateralType } from '../enums/CollateralType';
import { Equity } from '../enums/Equity';
import { TransactionType } from '../enums/TransactionType';
import { AmrDocument } from './AmrDocument';
import { Company } from './Company';
import { Contact } from './Contact';

export enum Category {
    'ArrangerPipeline' = 'ArrangerPipeline',
    'CleansingNotice' = 'CleansingNotice',
}

export const categoryLabels = {
    [Category.ArrangerPipeline]: 'Arranger Pipeline',
    [Category.CleansingNotice]: 'Cleansing Notice',
};

export interface CleansingNotice {
    className: string;
    cusip144A: string;
    cusipAccd: string;
    cusipRegS: string;
    dealTicker: string | null;
    isin144A: string;
    isinAccd: string;
    isinRegS: string;
    ticker144A: string;
    tickerAccd: string;
    tickerRegS: string;

    security?: DealSecurity;
}

export interface ArrangerPipeline {
    referenceName: string;
    dealReferenceName: string;
    collateralManager: Company;
    dealLegalName: string;
    dealTicker?: string;
    type: TransactionType | null;
    arranger?: Company;
    collateralType: CollateralType | null;
    nonCallPeriodEnd: number;
    reinvestmentPeriodEnd: number;
    expectedTiming: string;
    dealStage: string | null;
    euCompliance: boolean | null;
    numberOfTransactions: number;
    anchor: boolean | null;
    equity: Equity | null;
    lastUpdated: Date;
    arrangerReferenceName: string;
    collateralManagerReferenceName: string;
    syndicateContacts: Contact[];
    disclosureFile: AmrDocument;
    managerPresentationFile: AmrDocument;
    category: Category;
    currencyCode?: string;
    outOfRI?: boolean;
    outOfNC?: boolean;
    vintage?: Date;
    trustee?: Company;
    amrDeal?: boolean;
    esg?: boolean;
    staticDeal?: boolean;

    cleansingNotices: CleansingNotice[];

    nonCallPeriodEndDate: Date;
    reinvestmentPeriodEndDate: Date;

}
