import { useState } from "react";
import cn from "classnames";
import IconSVG from "../../../styles/svg-icons";
import { SinglePortfolioAlertsPopup } from "../../profile/tabs/email-preferences/tabs/portfolio/SinglePortfolioAlertsPopup";
import { Portfolio } from "../../../types/portfolio/Portfolio";
import { OnHoverTooltip } from "../../common/OnHoverTooltip";
import { user } from "../../../user/user";
import { roles } from "../../../constants/roles";
import { useAppSelector } from "../../../effects/useAppSelector";
import { Preloader } from "../../common";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";

interface Props {
    portfolio: Portfolio;
    disabled?: boolean;
}

export function SinglePortfolioAlertPreferences({ portfolio, disabled }: Props) {
    const [showModal, setShowModal] = useState(false);

    const isBwicAlertChanging = useAppSelector(s => s.portfolio.updateAlertRequestState[portfolio.id]);
    const isInventoryAlertChanging = useAppSelector(s => s.portfolio.updateInventoryAlertRequestState[portfolio.id]);
    const isCleansingNoticeAlertChanging = useAppSelector(s => s.portfolio.updateCleansingNoticeAlertRequestState[portfolio.id]);
    const isIssuanceMonitorAlertChanging = useAppSelector(s => s.portfolio.updateIssuanceMonitorAlertRequestState[portfolio.id]);
    const isOutOfRiAlertChanging = useAppSelector(s => s.portfolio.updateOutOfRiAlertRequestState[portfolio.id]);
    const isOutOfNcAlertChanging = useAppSelector(s => s.portfolio.updateOutOfNcAlertRequestState[portfolio.id]);
    const isRollerDeadlineAlertChanging = useAppSelector(s => s.portfolio.updateRollerDeadlineAlertRequestState[portfolio.id]);

    const isBd = user.hasRoles(...roles.bd());
    const tooltipText = `Receive email notifications when security from your portfolio is on a BWIC or has been added, updated, traded in the ${isBd ? '' : 'Dealer'} Inventory list.`;
    const isAlertSelected =
        portfolio.bwicAlert ||
        portfolio.dealersInventoryAlert ||
        portfolio.cleansingNoticeAlert ||
        portfolio.issuanceMonitorAlert ||
        portfolio.outOfRiPeriodAlert ||
        portfolio.outOfNcPeriodAlert ||
        portfolio.rollerDeadlineAlert;

    const isChanging =
        isBwicAlertChanging ||
        isInventoryAlertChanging ||
        isCleansingNoticeAlertChanging ||
        isIssuanceMonitorAlertChanging ||
        isOutOfRiAlertChanging ||
        isOutOfNcAlertChanging ||
        isRollerDeadlineAlertChanging;

    const hasAccess = user.hasAllFeatures(...[
        SubscriptionFeature.IssuanceMonitorFullAccess,
        SubscriptionFeature.PortfolioAlerts,
    ]);

    const limitedAlerts = [
        portfolio.cleansingNoticeAlert,
        portfolio.issuanceMonitorAlert,
        portfolio.outOfRiPeriodAlert,
        portfolio.outOfNcPeriodAlert,
        portfolio.rollerDeadlineAlert,
    ];

    const availableAlerts = [
        portfolio.bwicAlert,
        portfolio.dealersInventoryAlert,
        ...(hasAccess ? limitedAlerts : []),
    ];

    const allAlertsCount = availableAlerts.length + (hasAccess ? 0 : limitedAlerts.length);

    return (
        <>
            <Preloader small inProgress={isChanging}>
                <OnHoverTooltip
                    overlay={tooltipText}
                    disabled={disabled}
                    placement="topRight"
                >
                    <button
                        disabled={disabled}
                        className={cn('btn-link', { 'active': showModal })}
                        onClick={e => {
                            e.stopPropagation();
                            setShowModal(true);
                        }}
                    >
                        {isAlertSelected
                            ? <IconSVG name="alert" width={16} height={16} />
                            : <IconSVG name="alert-off" width={16} height={16} />}
                        {availableAlerts.reduce(( acc, alert ) => acc + Number(alert) , 0)}/{allAlertsCount}
                    </button>
                </OnHoverTooltip>
            </Preloader>
            {showModal && <SinglePortfolioAlertsPopup portfolio={portfolio} onClose={() => setShowModal(false)} />}
        </>
    );
}
