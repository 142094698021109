import { useSelector } from 'react-redux';
import { arrangerPipelineSelector } from '../../../../selectors/amr-pipeline.selector';
import { SummaryRow } from '../../../common/summary-row/SummaryRow';
import { SummaryRowItem } from '../../../common/summary-row/SummaryRowItem';
import { SummaryRowGroup } from '../../../common/summary-row/SummaryRowGroup';

export default function ArrangerPipelineSummary() {
    const {
        deals: { isSummaryRequesting, summary },
    } = useSelector(arrangerPipelineSelector);

    return (
        <SummaryRow isRequesting={isSummaryRequesting}>
            <SummaryRowItem label="Cleansing Notices" value={summary?.numberOfCleansingNotices} />
            <SummaryRowItem label="Arranger Pipelines" value={summary?.numberOfArrangerPipelines} />
            <SummaryRowItem label="Arrangers" value={summary?.numberOfArrangers} />
            <SummaryRowItem label="Managers" value={summary?.numberOfManagers} />
            <SummaryRowGroup groupName="Type">
                <SummaryRowItem label="New Issue" value={summary?.numberOfNewIssueTransactions} />
                <SummaryRowItem label="Refi" value={summary?.numberOfRefiTransactions} />
                <SummaryRowItem label="Reset" value={summary?.numberOfResetTransactions} />
                <SummaryRowItem label="TBD" value={summary?.numberOfTbdTypeTransactions} />
            </SummaryRowGroup>
            <SummaryRowGroup groupName="Collateral Type">
                <SummaryRowItem label="BSL" value={summary?.numberOfBslTransactions} />
                <SummaryRowItem label="MM" value={summary?.numberOfMmTransactions} />
                <SummaryRowItem label="TBD" value={summary?.numberOfTbdCollatTransactions} />
            </SummaryRowGroup>
        </SummaryRow>
    );
}
