import { useState } from "react";
import cn from "classnames";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { DealDocumentsPopup, DealDocumentsPopupTab } from "./DealDocumentsPopup";
import { DateTimeStamp } from "../../../types/primitives/DateTimeStamp";
import { constants } from "../../../constants";
import moment from "moment";

interface Props {
    className?: string
    security: {
        securityId: number
        isinCusip: string
        ticker: string
        cleansingNoticeDate?: DateTimeStamp
    }
    requiredFeature?: SubscriptionFeature | null
}

export function CleansingNoticeButton({
    className = "",
    security,
    requiredFeature = SubscriptionFeature.CanAccessDealDocuments }: Props) {
    const [popupVisible, setPopupVisible] = useState(false);

    if (!security.cleansingNoticeDate) return <>{constants.emptyPlaceholder}</>;

    return (
        <>
            <button
                className={cn("pseudo-link", className)}
                type="button"
                onClick={() => setPopupVisible(true)}
            >
                {moment(security.cleansingNoticeDate).format(constants.dateFormat)}
            </button>
            {popupVisible &&
                <DealDocumentsPopup
                    security={security}
                    defaultTab={DealDocumentsPopupTab.CleansingNotice}
                    onClose={() => setPopupVisible(false)}
                    requiredFeature={requiredFeature}
                />}
        </>
    );
}