import React, { ChangeEvent, useRef, useState } from 'react';
import MaskedInput from 'react-text-mask';
import cn from 'classnames';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { useAppSelector } from '../../../../../effects/useAppSelector';
import { Rating } from '../../../../../types/enums/Rating';
import { useDispatch } from 'react-redux';
import { manageEmailPreferencesActions } from '../../../../../actions/manage-email-preferences.actions';
import { constants, errorMessages } from '../../../../../constants';
import { emailPreferencesUtils } from '../../../../../utils/email-preferences.utils';
import { FormError } from '../../../../controls';
import { ActionBlocker } from '../../../../access/ActionBlocker';
import { SubscriptionFeature } from '../../../../../types/billing/SubscriptionFeature';

type SizeType = 'minSize' | 'maxSize';

interface Props {
    rating: Rating;
    type: SizeType;
    disabled: boolean;
}

export const EmailPreferencesTableSize: React.FC<Props> = ({ rating, type, disabled }) => {
    const dispatch = useDispatch();

    const [hover, setHover] = useState(false);

    const inputRef = useRef<MaskedInput | null>();

    const editingRating = useAppSelector(s =>
        s.manageEmailPreferences.editPreferences.ratingPreference.find(p => p.rating === rating)
    ) || emailPreferencesUtils.getDefaultRatingPreferences(rating);

    const value = editingRating[type];
    const isActive = editingRating.isUSD || editingRating.isEUR;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/,/g, '');
        dispatch(manageEmailPreferencesActions.setRatingSize(rating, Number(value), type));
        //Fix focus loose issue when error displayed
        setTimeout(() => inputRef.current?.inputElement.focus());
    };

    const getErrorMessage = () => {
        if (value < constants.emailPreferencesSizeRange.min || value > constants.emailPreferencesSizeRange.max) {
            return errorMessages.invalidRangeSize(constants.emailPreferencesSizeRange.min, constants.emailPreferencesSizeRange.max);
        }
        if (type === 'maxSize' && editingRating.minSize > editingRating.maxSize) {
            return errorMessages.maxSizeLessThenMin;
        }
        return null;
    };

    return (
        <>
            <ActionBlocker feature={SubscriptionFeature.BwicMonitorAlerts}>
                {blocked =>
                    <MaskedInput
                        ref={(r) => inputRef.current = r}
                        disabled={!(editingRating.isUSD || editingRating.isEUR) || disabled || blocked}
                        className={cn('form-control', { 'is-invalid': !!getErrorMessage() && isActive && !hover && !disabled })}
                        mask={createNumberMask({ prefix: '', includeThousandsSeparator: true })}
                        max={constants.emailPreferencesSizeRange.max}
                        onChange={handleChange}
                        value={blocked ? "" : value}
                        onFocus={() => setHover(true)}
                        onBlur={() => setHover(false)}
                    />}
            </ActionBlocker>
            <FormError
                message={!hover && !disabled && (editingRating.isUSD || editingRating.isEUR) && getErrorMessage()} 
            />
        </>
    )
};


