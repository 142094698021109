import { TransactionTypes } from "../types/amr-pipeline/enums/TransactionType";
import { TransactionStatuses } from "../types/amr-pipeline/enums/TransactionStatus";

export const constants = {
    datePickerFormat: 'M/D/YY',
    searchTermAcceptedLength: 2,
    dateFormat: 'MMM D, YYYY',
    dateFormat2: 'DD MMM YYYY',
    dateFormatDoubleDay: 'MMM DD, YYYY',
    dateFormat4: 'MM/DD/YY',
    dateFormat5: 'MM/DD/YYYY',
    issuanceMonitorExportDateFormat: 'MMM D YYYY',
    dateFormatISO8601: 'YYYY-MM-DD',
    dateShortFormat: 'M/D/YYYY',
    dashboardDateFormat: 'MM/DD/YY',
    rollerDeadlineFormat: 'ddd, MMM D, YYYY, h:mm A',
    timeFormat: 'hh:mm A',
    timeFormat2: 'hh:mm:ss A',
    timeFormat3: 'h A',
    dateTimeFormatUtc: 'YYYY-MM-DD[T]HH:mm:ss',
    dateTimeFormat: 'MMM D, YYYY hh:mm A',
    dateTimeFormatShort: 'M/D/YYYY hh:mm A',
    formatTimeStringWithoutTimeZone: 'YYYY-MM-DDTHH:mm:ss',
    billingHistoryTableDateTime: 'MM/DD/YYYY HH:mm',
    datePickerMaxYear: new Date().getFullYear() + 15,
    dayMs: 1000 * 60 * 60 * 24,
    regOnlyNumbers: /^[0-9]*$/,
    nonWhitespace: /.*\S+.*|^$/,
    website: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/,
    name: /(?=^[^~!@#$%^&*()_+{}[\]\\/<>?.,"|`=:;1234567890]*$).*\S+.*/,
    fullName: /^[\p{Letter}\p{Mark} ,.’'-]+$/u,
    contactsName: /^[a-z ]*$/i,
    USZipCode: /^(^[0-9-]+$|^$|^\s$)$/,
    zipCode: /^[A-Za-z \d-]{0,12}$/,
    date: /^\d{2}\/\d{2}\/\d{4}$/,
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
    taxIdPattern: /^(\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/,
    isinCusip:
        /((^US[0-9a-zA-Z]{9}[0-9]{1}$)|(^[a-zA-Z]{2}[0-9a-zA-Z]{9}[0-9]{1}$)|(^[0-9a-zA-Z]{9}$)|(^[a-zA-Z]{2}G{1}[0-9a-zA-Z]{8}[0-9]{1}$))/,
    bidNumber: /^\d+(\.\d{0,4})?$/,
    phoneMaxLength: 20,
    companyShortcode: /^[a-zA-Z]{1,5}$/,
    digitsOnly: /^\d+$/,
    allNonDigitSymbols: /[^\d]/g,
    targetLevel: /^\d{0,3}(\.\d{0,4})?$/,
    linkedIn: /^https:\/\/www\.linkedin\.com\/(?!.*\s).*$/,
    openBiddingMinStage1Duration: 15,
    openBiddingMinStage2Duration: 5,
    openBiddingMaxStage2Duration: 50,
    openBiddingMinBidIncrement: 0.01,
    openBiddingMaxBidIncrement: 10,
    openBiddingDefaultStage1Deadline: 30,
    defaultMinBidIncrement: 0.05,
    companyShortCodeMaxLength: 5,
    mpid: /^[a-zA-Z]{1,4}$/,
    gridFileUploadMaximumSize: 10,
    acceptedTemplateExtensions: '.csv,.xlsx',
    USCountryCode: 'US',
    USZipCodeSize: 5,
    PostalCodeSize: 12,
    newBidLifetimeLimitSecond: 5,
    notificationService: '/notification',
    notificationServiceReconnectTimeout: 10000,
    notificationListDisplayLimit: 1,
    notificationDisplayTimeout: 7000,
    bidRange: { min: 0.01, max: 150 },
    stage1AboutToEndTimeoutMinutesRange: { min: 1, max: 100 },
    targetLevelRange: { min: 0.01, max: 150 },
    bidMinStep: 0.0001,
    actionMessageAvatarNumber: 10,
    bwicAllowedTimeRange: { from: '8:00 AM', to: '9:00 PM' },
    profileAddTradersLimit: 20,
    pxTalkCharactersAfterDot: 2,
    gridRowsLimit: 500,
    pushNotificationsServiceRestartTimeout: 5000,
    pushNotificationsServiceRestartAttemptsLimit: 5,
    tokenExpirationLimitSeconds: 60,
    refreshTokenExpirationLimitSeconds: 10,
    verifyIdentityCodeLifetimeMinutes: 10,
    accountLockTimeoutMinutes: 10,
    reSendIdentityCodeTimeoutSeconds: 60,
    verifyIdentityCodeLength: 6,
    sendVerificationCodeAttemptsLimit: 5,
    commissionRange: { min: 0.0001, max: 100 },
    commissionDecimalPlaces: 4,
    dailyTradingLimit: { min: 10000, max: 999999999 },
    defaultCommission: 0.02,
    defaultDailyTradingLimit: 100000000,
    emptyPlaceholder: '—',
    newsOnPage: 20,
    maxCountNewsOnSlider: 4,
    defaultTransactionsSearchCriteria: {
        types: [...TransactionTypes.map(t => t.value)],
        statuses: [...TransactionStatuses.map(s => s.value)],
        offset: 0,
        count: 40,
    },
    defaultArrangerPipelineOrderCriteria: [
        {
            field: 'lastUpdated',
            ascending: false,
        },
    ],
    defaultDealOrderCriteria: [
        {
            field: 'lastUpdated',
            ascending: false,
        },
    ],
    defaultClassOrderCriteria: [
        {
            field: 'lastUpdated',
            ascending: false,
        },
        {
            field: 'dealName',
            ascending: false,
        },
        {
            field: 'classOrder',
            ascending: true,
        },
    ],
    defaultDealSearchCriteria: {
        offset: 0,
        count: 40,
    },
    maxContactsInCompany: 20,
    paymentCardsLimit: 10,
    trialPeriodDays: 30,
    welcomePopupStorageKey: 'is-welcome-popup-shown',
    trialCongratulationsBottomPanelKey: 'trialCongratulationsBottomPanelKey',
    trialExpiredBottomPanelKey: 'trialExpiredBottomPanelKey',
    salesRepresentativesLimit: 5,
    currencyConversionRate: { min: 0.001, max: 9.999 },
    datePickerMinYear: 2010,
    minChartYear: 2011,
    sellerAdminTradingLimits: { min: 0, max: 500000000000 },
    bobAutoSaveIntervalSeconds: 5,
    tableRowHeight: 32,
    tableHeaderHeight: 24,
    bidDueDefaultTimestamp: '10:00 AM',
    goodUntilDefaultTimestamp: '12:00 PM',
    emailPreferencesSizeRange: { min: 10000, max: 999999999 },
    daysToSettleDefault: 1,
    usBslEsgChartDefaultDuration: 12,
    allListItem: '__all__',
    settlementAgentDefaultCommission: 0.0001,
    estTimezone: 'America/New_York',
    documentMaxFilesizeInMb: 20,
    introduceDirectBiddingPopupMaxWatchCount: 5,
    requestAllToAllTradingPopupMaxWatchCount: 5,
    requestDirectBiddingPopupLocalStorageKey: 'requestDirectBiddingPopupClosedManually',
    requestAllToAllPopupLocalStorageKey: 'requestAllToAllPopupLocalStorageKey',
    imSubscriptionTrialEnabledPopupStorageKey: 'imSubscriptionTrialPopupShowed',
    imSubscriptionTrialExpiredPopupStorageKey: 'imSubscriptionTrialExpiredPopupShowed',
    autoFeedbackDelayDefaultMinutes: 20,
    autoFeedbackDelayRange: { min: 1, max: 20 },
    minBidIncrementRange: { min: 0.01, max: 10 },
    cutOffReminderRangeDefaultMinutes: 20,
    cutOffReminderRange: { min: 2, max: 20 },
    differenceBetweenStageAndCutOffReminder: 2,
    minBwicDuration: 15,
    jumpBall: {
        stage1EndAutoDefaultMinutes: 30,
        stage1EndAutoRange: { min: 15, max: 120 },
        stage2DurationDefaultMinutes: 30,
        stage2DurationRange: { min: 5, max: 120 },
    },
    topX: {
        stage1EndAutoDefaultMinutes: 30,
        stage1EndAutoRange: { min: 15, max: 120 },
        stage2DurationDefaultMinutes: 30,
        stage2DurationRange: { min: 5, max: 120 },
    },
    bestFootForward: {
        stage1DeadlineDefaultMinutes: 30,
        stage1DeadlineRange: { min: 15, max: 120 },
    },
    MAX_BACKEND_INT: 2147483647,
    cusipLength: 9,
    isinLength: 12,
    filterTradedColorRange: { min: 0, max: 200 }
};
