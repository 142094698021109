import * as React from 'react';
import { RequestState } from '../constants/request-state';
import { documentsService } from '../services/documents.service';
import { logger } from '../logging/logger';

export function useSecurityDocumentsExistFlag(isinCusip?: string, disabled: boolean = false) {
    const [state, setState] = React.useState({ isExist: false, requestState: RequestState.none });

    React.useEffect(() => {
        const request = async () => {
            if (disabled) return;

            try {
                const isExist: boolean = await documentsService.getSecurityDocumentsExistFlag(isinCusip);
                setState({ isExist, requestState: RequestState.success });
            } catch (e) {
                logger.exception(e as Error, 'Failed to request company documents exist flag');
                setState({ isExist: true, requestState: RequestState.failure });
            }
        }

        if (isinCusip) {
            setState({ isExist: false, requestState: RequestState.request });
            request();
        }
    }, [disabled, isinCusip]);

    const result: [requestState: RequestState, isDocumentsExist: boolean] = [state.requestState, state.isExist];

    return result;
}
