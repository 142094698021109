import React, { useEffect, useState } from "react"
import cn from 'classnames';
import MaskedInput from "react-text-mask"
import { NumberMaskOptions } from "../../controls/DecimalMaskedInput"
import { createNumberMask } from "text-mask-addons"
import { FormFieldLabel } from '../../forms';
import { formatUtils, numericUtils, stringUtils } from '../../../utils';
import { constants, errorMessages } from '../../../constants';
import { ClickOutside } from '../ClickOutside';
import { FilterDropDown } from './FilterDropDown';
import { FilterGroup, FilterRangeGroup, FilterSelectGroup } from '../../../types/filters/FilterGroup';
import { Checkbox, FormError } from '../../controls';
import { ColorFilterType } from '../../../types/bwic/Color';
import { useAppDispatch } from '../../../effects/useAppDispatch';
import { TFilterType } from '../../../types/filters/FilterState';
import { createFilterActions } from '../../../actions/filter.actions';
import { FilterButton } from "./FilterButton";
import { FilterOption } from "../../../types/filters/FilterOption";


interface Props {
    defaultExpanded?: boolean
    disabled?: boolean
    group: FilterGroup[]
    filterType: TFilterType
}

export function FilterColor({
    group,
    defaultExpanded = false,
    disabled = false,
    filterType,
}: Props) {
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState(defaultExpanded);
    const [error, setError] = useState(false);

    const [color, range] = group as [FilterSelectGroup<number>, FilterRangeGroup];

    const tradedColorSelected = color.filter.some(o => o.selected && o.value === ColorFilterType.TradedColor)
    const selected = color.filter.some(o => o.selected);
    const isResetDisabled = !selected;
    const isApplied = color.isApplied;

    const filterActions = createFilterActions(filterType);

    const renderSingleSelectionTitle = (selectedOption: FilterOption) => {
        const hasRange =
            !error && (
                numericUtils.isNumber(range.filter.from) ||
                numericUtils.isNumber(range.filter.to)
            );

        let text = selectedOption.text
        if (tradedColorSelected && hasRange) {
            const from = formatUtils.formatDecimal(+(range.filter.from ?? constants.filterTradedColorRange.min), 2);
            const to = formatUtils.formatDecimal(+(range.filter.to ?? constants.filterTradedColorRange.max), 2);

            text = `${from} - ${to}`;
        }

        return <>Color:&nbsp;<span className="selected-data">{text}</span></>
    }

    const renderTitle = () => {
        const selectedOptions = color.filter.filter(o => o.selected);

        if (selectedOptions.length === 1) {
            return renderSingleSelectionTitle(selectedOptions[0]);
        } else if (
            selectedOptions.length > 1 &&
            selectedOptions.length < color.filter.length
        ) {
            return <>Color: {selectedOptions.length} selected</>
        }

        return `Color: All`;
    }

    function handleClearAll() {
        dispatch(filterActions.filterSelectClearAll(color.key));
        dispatch(filterActions.filterRangeClearSelectedOptions(range.key));
        setError(false);
    }

    const handleSelectionChange = (value: number) => {
        dispatch(filterActions.filterSelectChange(value, color.key));

        if (tradedColorSelected && value === ColorFilterType.TradedColor) {
            dispatch(filterActions.filterRangeClearSelectedOptions(range.key));
            setError(false);
        }
    }

    const renderOptions = () =>
        <ul className="control-filter-select-list">
            {color.filter.map(o =>
                <li key={o.value}>
                    <Checkbox
                        label={o.text}
                        checked={o.selected}
                        onChange={() => handleSelectionChange(o.value)}
                    />
                </li>
            )}
        </ul>

    return (
        <ClickOutside className="control-filter-select control-filter-color" onClick={() => setTimeout(() => setExpanded(false))}>
            <FilterButton
                title={renderTitle()}
                expanded={expanded}
                applied={isApplied}
                selected={selected}
                error={error}
                onExpand={setExpanded}
                onClearAll={handleClearAll}
                disabled={disabled}
            />
            {!disabled && expanded && (
                <FilterDropDown
                    className="control-filter-content-color flex-row"
                    expanded={expanded}
                    value={color.filter}
                >
                    <div className="control-filter-content-select">
                        <button
                            className="btn-link"
                            disabled={isResetDisabled}
                            onClick={handleClearAll}
                        >
                            Reset to default
                        </button>
                        {renderOptions()}
                    </div>
                    {tradedColorSelected &&
                        <RangeInput
                            range={{
                                from: numericUtils.isNumber(range.filter.from) ? +range.filter.from : undefined,
                                to: numericUtils.isNumber(range.filter.to) ? +range.filter.to : undefined
                            }}
                            limits={{ from: constants.filterTradedColorRange.min, to: constants.filterTradedColorRange.max }}
                            mask={{
                                prefix: '',
                                suffix: '',
                                includeThousandsSeparator: false,
                                allowDecimal: true,
                                decimalLimit: 2,
                                integerLimit: 3,
                                requireDecimal: false,
                            }}
                            onChange={(newRange, hasError) => {
                                setError(hasError);
                                dispatch(filterActions.changeRangeFilter(newRange, range.key))
                            }}
                        />
                    }
                </FilterDropDown>
            )}
        </ClickOutside>
    )
}

const errorDefaults = { from: "", to: "" };

interface Range {
    from?: number
    to?: number
}

interface RangeInputProps {
    range: Range
    limits: Range
    mask: Partial<NumberMaskOptions>
    onChange: (range: Range, hasError: boolean) => void
}

function RangeInput({ range, limits, mask, onChange }: RangeInputProps) {
    const [errors, setErrors] = useState(errorDefaults);
    const [from, setFrom] = useState(range.from?.toString() ?? "");
    const [to, setTo] = useState(range.to?.toString() ?? "");

    useEffect(() => {
        const validate = () => {
            if (!stringUtils.isNullOrEmpty(from) && !stringUtils.isNullOrEmpty(to) && +from > +to) {
                setErrors({ from: errorMessages.fromRangeBiggerThenToRange, to: '' });
            } else {
                setErrors(errorDefaults);
            }
        };

        validate();
    }, [from, to])

    const cutToLimits = (value: number) => {
        let next = limits.from == null ? value : Math.max(value, limits.from);
        next = limits.to == null ? next : Math.min(next, limits.to);

        return next;
    }

    const getRange = () => ({
        from: numericUtils.isNumber(from) ? cutToLimits(+from) : undefined,
        to: numericUtils.isNumber(to) ? cutToLimits(+to) : undefined
    })

    const getPlaceholder = (value: number | string) => {
        if (typeof value === 'string') return String(value);

        if (numericUtils.isNumber(value)) {
            return mask.allowDecimal && mask.decimalLimit
                ? formatUtils.formatDecimal(Number(value), mask.decimalLimit) ?? ''
                : String(value);
        }

        return '';
    }

    const handleBlur = () => {
        const hasErrors = !!(errors.from || errors.to);
        const range = getRange();

        if (range.from != null) setFrom(range.from.toString());
        if (range.to != null) setTo(range.to.toString())

        onChange(range, hasErrors);
    }

    const renderInput = (
        label: string,
        value: string,
        placeholder: string,
        error: string | undefined,
        onChange: (value: string) => void
    ) =>
        <div className="form-control-wrapper">
            <FormFieldLabel text={label} />
            <MaskedInput
                value={value}
                className={cn('form-control', { 'is-invalid': !!error })}
                mask={createNumberMask(mask)}
                placeholder={placeholder}
                onChange={e => onChange(e.target.value)}
                onBlur={handleBlur}
            />
            <FormError message={error} />
        </div>

    return (
        <div className="control-filter-content-range">
            <div className="control-filter-range-row">
                {renderInput("From", from, getPlaceholder(limits.from ?? 0), errors.from, (value: string) => setFrom(value))}
                <span className="dash">{constants.emptyPlaceholder}</span>
                {renderInput("To", to, getPlaceholder(limits.to ?? 0), errors.to, (value: string) => setTo(value))}
            </div>
        </div>
    );
}