import { keys } from "lodash"
import { QueryStringArgumentSerializer } from "./QueryStringArgumentSerializer";
import { ColorFilterType } from "../../../types/bwic/Color";

export class ColorArgumentSerializer extends QueryStringArgumentSerializer<string[]> {
    constructor(onDeserializeCallback?: (value: string[]) => void) {
        super("color", keys(ColorFilterType), onDeserializeCallback);
    }
    serialize = (values: string[] | undefined) => {
        if(!values?.length) return undefined;

        return values
            ?.map(x => ColorFilterType[Number(x)])
            .join(',')
    }

    deserialize = (rawValue: string): void => {
        //convert status titles to status values
        const statusValues =
            rawValue
                .split(',')
                .map(value => value.trim())
                .filter(value => value && this.isAccepted(value))
                .map(value => ColorFilterType[value as keyof typeof ColorFilterType].toString());

        if(statusValues?.length){
            this.onDeserializeCallback?.(statusValues);
        }
    }
}
