export const textLabels = {
    // Inventory alert option descriptions
    savedFiltersBwicMonitor: 'Receive an email when securities matching your saved filter are on a BWIC.',
    savedFiltersInventory:
        'Receive an email when security matches your saved filter has been added, updated, or marked as traded in the Dealer Inventory.',
    // Tooltips
    savedFiltersDefaultDailyTooltip: 'Alerts are sent at 7 AM EST every business day excluding holidays',
    savedFiltersDefaultInstantTooltip:
        'Alerts are sent once the BWIC is posted with securities matching your saved filters.',
    savedFiltersInventoryInstantTooltip:
        'Alerts are sent once security matches your saved filter has been added, updated, or marked as traded in the Dealer Inventory',

    // Portfolio alert option descriptions
    savedFiltersPortfolioBwicAlertDescription:
        'Receive email notifications once security from the portfolio is on a BWIC or Post-BWIC color is added.',
    savedFiltersPortfolioCleansingNoticeAlertDescription:
        'Receive email notifications when the new cleansing notice is available for securities from the portfolio.',
    savedFiltersPortfolioInventoryAlertDescription:
        'Receive email notifications once securities from the portfolio are added/updated/traded in the Dealer Inventory List.',
    savedFiltersPortfolioIssuanceMonitorAlertDescription:
        'Receive email notifications when a deal involving security from the portfolio is on the Issuance Monitor.',
    savedFiltersPortfolioOutOfNcAlertDescription:
        'Receive email notifications 3/2/1 month(s) before and on the date when securities from the portfolio exit the Non-Call Period.',
    savedFiltersPortfolioOutOfRiAlertDescription:
        'Receive email notifications 3/2/1 month(s) before and on the date when securities from the portfolio exit the Reinvestment Period.',
    savedFiltersPortfolioRollerDeadlineAlertDescription:
        'Receive email notifications instantly, at 8 AM EST on the day it ends, and 3 hours before it expires, as securities in the portfolio hit the Roller Deadline.',
    // Portfolio alert tooltips
    savedFiltersPortfolioBwicInstantAlert:
        'Alerts are sent once BWIC is posted/Post-BWIC color is added on the security from your portfolio.',
    savedFiltersPortfolioCleansingNoticeInstantAlert:
        'Alerts are sent when the new cleansing notice is available for securities from the portfolio.',
    saveFiltersPortfolioInventoryInstantsAlert:
        'Alerts are sent once securities from your portfolio are added/updated/marked as traded in the Dealer Inventory.',
    savedFiltersPortfolioIssuanceMonitorInstantAlert:
        'Alerts are sent once deals involving securities from your portfolio are published on the Issuance Monitor.',
    savedFiltersPortfolioOutOfNcPeriodicAlert:
        'Alerts are sent at 9 AM EST 3/2/1 month(s) before and on the date when securities from your portfolio exit the Non-Call Period.',
    savedFiltersPortfolioOutOfRiPeriodicAlert:
        'Alerts are sent at 9 AM EST 3/2/1 month(s) before and on the date when securities from your portfolio exit the Reinvestment Period.',    
    savedFiltersPortfolioRollerDeadlineInstantAlert:
        'Alerts are sent once deals involving securities from your portfolio are published on the Issuance Monitor with the Roller Deadline date, at 8 AM EST on the day it ends, and 3 hours before it expires.',
};
