import { entries, sumBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { portfolioActions as actions, portfolioActions, searchSecuritiesActions } from '../../actions';
import { portfolioListActions } from '../../actions/portfolio-list.actions';
import { onBoardingTooltipConst, routes } from '../../constants';
import { roles } from '../../constants/roles';
import { useAppSelector } from '../../effects/useAppSelector';
import { useLocationStateReset } from '../../effects/useLocationStateReset';
import { getPortfolios } from '../../selectors/portfolio.selector';
import IconSVG from '../../styles/svg-icons';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { BwicStatus } from '../../types/enums/BwicStatus';
import { BwicFilterType } from '../../types/filters/FilterState';
import { PageConfigType } from '../../types/page-config/PageConfigType';
import { Portfolio } from '../../types/portfolio/Portfolio';
import { LocationStateBuilder, PopupType } from '../../types/state/ui/LocationState';
import { apiUtils, arrayUtils, isRequestFailed, isRequesting, isRequestNone, isRequestSuccess } from '../../utils';
import { ActionBlocker } from '../access/ActionBlocker';
import { FeatureButton } from '../access/FeatureButton';
import { ControlPanelOptions } from '../bidding/common/ControlPanelOptions';
import { EmptyPlaceholder, OnHoverTooltip, Preloader, Relative } from '../common';
import { NewBwicButton } from '../common/new-bwic-button/NewBwicButton';
import { BwicColumnCustomizer } from '../common/pageConfig/column-customizer/BwicColumnCustomizer';
import { usePageConfig } from '../common/pageConfig/usePageConfig';
import { SecuritiesSearchInput } from '../filters';
import { OnBoardingTooltip } from '../onBoarding/OnBoardingTooltip';
import { CreatePortfolioButton } from '../portfolio-agreement-disclaimer/CreatePortfolioButton';
import { PortfolioAgreementDisclaimerActionBlocker } from '../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerActionBlocker';
import { PortfolioAgreementDisclaimerContentBlocker } from '../portfolio-agreement-disclaimer/PortfolioAgreementDisclaimerContentBlocker';
import { RequestSettlementAgentAgreementBanner } from '../request-settlement-agent-agreement/RequestSettlementAgentAgreementBanner';
import { SecurityDetailsPanel } from '../security-details';
import { SecurityUsagePopup, TSecurityUsagePopupPayload } from '../security-usage-popup/SecurityUsagePopup';
import { PortfolioFilters } from './filter/PortfolioFilters';
import { PortfolioTable } from './portfolio-table/PortfolioTable';
import { PortfolioControlPanel } from './PortfolioControlPanel';
import { PortfolioAlertsPreferences } from './portfolio-alert/PortfolioAlertsPreferences';
import { BlockedFeatureContent, SubscribeLink } from '../access/BlockedFeatureText';
import { useAppDispatch } from '../../effects/useAppDispatch';
import { RoleActionBlocker } from '../access/RoleActionBlocker';
import { useSecurityLatestTransaction } from '../../effects/data-accessors/useSecurityLatestTransaction';
import { useSecurityArrangersPipeline } from '../../effects/data-accessors/useSecurityArrangersPipeline';
import { DealDocumentsAutoOpener } from './DealDocumentsAutoOpener';

const PortfolioComponent = () => {
    const pageConfigType = PageConfigType.Portfolio;
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isLoading = useAppSelector(({ entities: { portfolios: { requestState } } }) =>
        isRequesting(requestState) || isRequestNone(requestState));
    const portfolios = useAppSelector(s => s.entities.portfolios.items);
    const filteredPortfolios: Portfolio[] = useAppSelector(getPortfolios);
    const selectedState = useAppSelector(s => s.portfolio.selectedState);
    const searchTermItems = useAppSelector(s => s.searchSecurities.searchTermItems) || [];
    const selectedBwic = useAppSelector(s => s.portfolio.selectedBwic);
    const idRequestingExportPortfolio = useAppSelector(s => s.portfolio.idRequestingExportPortfolio);
    const {
        config, requestState: pageConfigRequestState, defaultConfigRequestState
    } = usePageConfig(pageConfigType, false, BwicFilterType.Portfolio);
    const isPageConfigLoaded = isRequestSuccess(pageConfigRequestState, defaultConfigRequestState)
        || isRequestFailed(pageConfigRequestState, defaultConfigRequestState);

    const hasFilteredPortfolios = !!filteredPortfolios.length;
    const hasPortfolios = !!portfolios.length;
    const adavancedFiltersBlocked = useAppSelector(s => s.portfolio.advancedFilterBlocked);

    const tickers = useMemo(() => {
        const allTickers =
            portfolios
                .map(p => p.securities)
                .flat()
                .map(s => s.dealTicker)
                .filter(t => t)
                .map(t => t!) // avoid TS error

        return [...new Set(allTickers)]
    }, [portfolios])

    useSecurityLatestTransaction(tickers);
    useSecurityArrangersPipeline(tickers);

    useLocationStateReset();

    useEffect(() => {
        if (isPageConfigLoaded) dispatch(portfolioListActions.request());
        return () => {
            dispatch(actions.reset());
        }
    }, [dispatch, isPageConfigLoaded])

    const hasSecurities = useMemo(
        () => hasPortfolios && portfolios.some(p => p.securities.length),
        [hasPortfolios, portfolios]
    );

    const selectedSecurityCount = useMemo(
        () => sumBy(
            Object.values(selectedState),
            p => Object.values(p.securitiesSelectedState).filter(selected => selected).length
        ),
        [selectedState]
    );

    const handleClick = () => history.push(routes.newPortfolio);

    const handleResetFilterAndSearch = () => {
        dispatch(searchSecuritiesActions.reset());
        dispatch(actions.resetFilter());
    }

    const renderAlertToggle = useMemo(() => (
        <OnHoverTooltip
            disabled={hasPortfolios || isLoading}
            overlay="Alerts can't be turned On. You have not created any portfolios yet."
        >
            <div className="global-portfolio-alert-selector">
                <PortfolioAlertsPreferences disabled={isLoading || !hasPortfolios} />
            </div>
        </OnHoverTooltip>
    ), [hasPortfolios, isLoading]);

    const renderTable = () => {
        if (adavancedFiltersBlocked) {
            return <BlockedFeatureContent text={<><SubscribeLink /> to see Portfolio details</>} />
        }
        if (hasPortfolios) {
            return filteredPortfolios.length
                ? <PortfolioTable pageConfig={config} filteredPortfolios={filteredPortfolios} />
                : <EmptyPlaceholder onResetClick={handleResetFilterAndSearch}/>
        }
        return (
            <EmptyPlaceholder title="No Portfolio Created" text="">
                <p>
                    You have not created any portfolios yet.<br />
                    To create a portfolio, click "Add Portfolio" below.
                </p>
                <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                    <CreatePortfolioButton
                        type="button"
                        className="btn btn-main"
                        onClick={handleClick}
                    >
                        <span>Add Portfolio</span>
                    </CreatePortfolioButton>
                </PortfolioAgreementDisclaimerActionBlocker>
            </EmptyPlaceholder>
        )
    }

    return (
        <PortfolioAgreementDisclaimerContentBlocker disabled={!hasPortfolios && !isLoading}>
            <div className="container">
                <div className="portfolio">
                    <div className="sub-header sub-header-pipeline">
                        <div className="sub-header-row type01 flex-row">
                            <h1>Portfolio</h1>
                            <div className="search-area">
                                <SecuritiesSearchInput
                                    placeholder="Search by portfolio name, ISIN/CUSIP or ticker"
                                    disabled={isLoading || (!hasPortfolios && !searchTermItems.length)}
                                    onSearch={() => dispatch(actions.applySearchInput())}
                                    requiredFeature={SubscriptionFeature.PortfolioSecuritySearch}
                                />
                                <OnBoardingTooltip
                                    page={onBoardingTooltipConst.pages.portfolio}
                                    step={2}
                                    hidden={isLoading}
                                />
                            </div>
                            <div className="flex-item-right controls flex-row">
                                <div className="btn-link-group">
                                    <Relative inline>
                                        {renderAlertToggle}
                                        <OnBoardingTooltip
                                            page={onBoardingTooltipConst.pages.portfolio}
                                            step={4}
                                            className="popover-onboarding-right"
                                            hidden={isLoading}
                                        />
                                    </Relative>
                                    <Relative inline>
                                        <ActionBlocker feature={SubscriptionFeature.exportPortfolios}>
                                            <div className="export-portfolio">
                                                <Preloader small={true} fullScreen={false}
                                                    inProgress={idRequestingExportPortfolio} text="Exporting…">
                                                    <FeatureButton
                                                        className="btn-link margin-l-0"
                                                        disabled={!hasPortfolios || isLoading || !hasSecurities || !hasFilteredPortfolios}
                                                        onClick={() => dispatch(actions.exportAll())}
                                                    >
                                                        <IconSVG name="export" width={16} height={16} />
                                                        Export
                                                    </FeatureButton>
                                                </Preloader>
                                            </div>
                                        </ActionBlocker>
                                        <OnBoardingTooltip
                                            page={onBoardingTooltipConst.pages.portfolio}
                                            step={3}
                                            className="popover-onboarding-right"
                                            hidden={isLoading}
                                        />
                                    </Relative>
                                    <Relative inline>
                                        <PortfolioAgreementDisclaimerActionBlocker onConfirm={handleClick}>
                                            <CreatePortfolioButton
                                                type="button"
                                                disabled={isLoading}
                                                className="btn-link"
                                                onClick={handleClick}
                                            >
                                                <IconSVG name="new-portfolio" width={16} height={16} />
                                                <span>New Portfolio</span>
                                            </CreatePortfolioButton>
                                            <OnBoardingTooltip
                                                page={onBoardingTooltipConst.pages.portfolio}
                                                step={1}
                                                className="popover-onboarding-right"
                                                hidden={isLoading}
                                            />
                                        </PortfolioAgreementDisclaimerActionBlocker>
                                    </Relative>
                                    <RoleActionBlocker roles={roles.getAllRolesExclude(roles.Media, roles.Viewer)}>
                                        {(blocked) => (
                                            <button
                                                className="btn-link margin-r-0"
                                                disabled={!hasPortfolios || isLoading || !hasFilteredPortfolios || blocked}
                                                onClick={() => history.push(routes.portfolioDocuments)}
                                            >
                                                <IconSVG name="upload" width={16} height={16} />
                                                <span>Upload Docs</span>
                                            </button>
                                        )}
                                    </RoleActionBlocker>
                                </div>
                                <PortfolioNewBwicButton count={selectedSecurityCount} />
                            </div>
                        </div>
                        <div className="sub-header-row type02 flex-row align-items-flex-start row-align-filters">
                            <PortfolioFilters />
                            <div className="controls flex-none">
                                {/*<button type="button" className="btn btn-link">
                                        <i className="icon icon-settings"></i>
                                        Configure grid
                                    </button>*/}
                                <BwicColumnCustomizer
                                    tabs={{ 'All': PageConfigType.Portfolio }}
                                    disabled={!isPageConfigLoaded || isLoading || !hasPortfolios}
                                    defaultTab={pageConfigType}
                                    requiredFeature={SubscriptionFeature.PortfolioColumnCustomizer} />
                            </div>
                        </div>
                    </div>
                    <RequestSettlementAgentAgreementBanner />
                </div>
                <div className="portfolio-cnt container">
                    <Preloader inProgress={isLoading}>
                        {
                            !isLoading &&
                            <>
                                {renderTable()}
                                {
                                    selectedBwic
                                        ? <PortfolioControlPanel />
                                        : <SecurityDetailsPanel
                                            optionsExclude={[ControlPanelOptions.addToPortfolio]}
                                            onClose={() => dispatch(portfolioActions.resetRowSelection())}
                                        />
                                }
                            </>
                        }
                    </Preloader>
                </div>
            </div>
            <DealDocumentsAutoOpener />
        </PortfolioAgreementDisclaimerContentBlocker>
    );
}

export { PortfolioComponent as Portfolio }


interface PortfolioNewBwicButtonProps {
    count: number;
}

function PortfolioNewBwicButton({ count }: PortfolioNewBwicButtonProps) {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const portfolios = useAppSelector(s => s.entities.portfolios.items);
    const selectedState = useAppSelector(s => s.portfolio.selectedState)

    const handleNewBwicClick = () => {
        const securities =
            entries(selectedState)
                .map(([portfolioId, selected]) => {
                    const portfolio = portfolios.find(p => p.id === +portfolioId);
                    return portfolio
                        ? portfolio.securities.filter(s => selected.securitiesSelectedState[s.id])
                        : []
                }).flat()

        const selectedSecurities = arrayUtils.distinct(securities, s => s.id);

        if (selectedSecurities.length) {
            const tickers = selectedSecurities.map(s => s.ticker);
            const bwicsHistory = apiUtils.normalize(
                selectedSecurities,
                s => s.ticker,
                s => s.history.filter(h =>
                    h.status === BwicStatus.scheduled ||
                    h.status === BwicStatus.bidding)
            );

            history.push({
                ...history.location,
                state: new LocationStateBuilder<unknown, TSecurityUsagePopupPayload>()
                    .popup(PopupType.SecurityUsage, { tickers, bwicsHistory })
                    .result()
            });
        } else {
            dispatch(actions.createBwicConfirmed());
        }
    }

    return (
        <SecurityUsagePopup
            disabled={!count}
            onConfirm={() => dispatch(actions.createBwicConfirmed())}
        >
            {
                requesting => requesting
                    ? <button disabled className="btn btn-main">Loading...</button>
                    : <NewBwicButton handleClick={handleNewBwicClick} selectedSecurityCount={count} />
            }
        </SecurityUsagePopup>
    );
}
