export enum PipelineColumn {
    dealName = 'dealName',
    toRelatedDeals = 'toRelatedDeals',
    manager = 'manager',
    arranger = 'arranger',
    toAllArrangers = 'toAllArrangers',
    toAllManagers = 'toAllManagers',
    transactionStatus = 'transactionStatus',
    transactionType = 'transactionType',
    transactionSize = 'transactionSize',
    dealSize = 'dealSize',
    pricingDate = 'pricingDate',
    closingDate = 'closingDate',
    nonCallPeriodEnd = 'nonCallPeriodEnd',
    reinvestmentPeriodEnd = 'reinvestmentPeriodEnd',
    was = 'was',
    warf = 'warf',
    ds = 'ds',
    className = 'className',
    cusip = 'cusip144A',
    rating = 'rating',
    balance = 'balance',
    parSubordination = 'parSubordination',
    mvoc = 'mvoc',
    wal = 'wal',
    coupon = 'coupon',
    dm = 'dm',
    price = 'price',
    initialPrice = 'initialPrice',
    guidance = 'guidance',
    initialGuidance = 'initialGuidance',
    subscription = 'subscription',
    target = 'target',
    trigger = 'trigger',
    cushion = 'cushion',
    margin = 'margin',
    factor = 'factor',
    capMargin = 'capMargin',
    nonAmr = 'nonAMR',
    collateralType = 'collateralType',
    euCompliance = 'euCompliance',
    esg = 'esg',
    amr = 'amr',
    amrDeal = 'amrDeal',
    amrClass = 'amrClass',
    currency = 'currency',
    offeringType = 'offeringType',
    lastUpdated = 'lastUpdated',
    isNew = 'isNew',
    maturity = 'maturity',
    vintage = 'vintage',
    firstPayment = 'firstPayment',
    intex = 'intex',
    numberOfDocuments = 'numberOfDocuments',
    syndicate = 'syndicate',
    enhancedCLO = 'enhancedCLO',
    staticDeal = 'staticDeal',
    rollerDeadline = 'rollerDeadline',
    dealTicker = 'dealTicker',
    originatingTransactionClassStatus = 'originatingTransactionClassStatus',
    iois = 'iois',
    shareOption = 'shareOption',
    options = 'options',
    optionsAction = 'optionsAction',
    optionButton = 'optionButton',
    ticker = 'ticker144A',
    isin = 'isin144A',
    tickerRegS = 'tickerRegS',
    cusipRegS = 'cusipRegS',
    isinRegS = 'isinRegS',
    tickerAccdInvCertif = 'tickerAccdInvCertif',
    cusipAccdInvestor = 'cusipAccdInvCertif',
    isinAccdInvestor = 'isinAccdInvCertif',
    expectedTiming = 'expectedTiming',
    dealStage = 'dealStage',
    anchor = 'anchor',
    equity = 'equity',
    disclosureFile = 'disclosureFile',
    trustee = 'trustee',
    managerPresentationFile = 'managerPresentationFile',
    legalNameOnDeal = 'legalNameOnDeal',
    outOfNC = 'outOfNC',
    outOfRI = 'outOfRI',
    reinvestmentPeriodEndYears = 'reinvestmentPeriodEndYears',
    nonCallPeriodEndYears = 'nonCallPeriodEndYears',
    reinvestmentPeriodEndDays = 'reinvestmentPeriodEndDays',
    nonCallPeriodEndDays = 'nonCallPeriodEndDays',
    category = 'category',
    static = 'static',
};
