import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { Action, ActionType, getType } from 'typesafe-actions';
import { AppState } from '../types/state/AppState';
import { constants, gridColumns, roles } from '../constants';
import { PipelineType } from '../types/amr-pipeline/enums/PipelineType';
import { ArrangerPipelineFilters, PipelineFilterState } from '../types/state/PipelineFilterState';
import { amrArrangerPipelineActions } from '../actions/amr-arranger-pipeline.actions';
import { ArrangerPipelineState } from '../types/state/ArrangerPipelineState';
import {
    selectedBooleanOption,
    selectedRadioOption,
    selectedRangeOptions,
    selectedSelectGroupOptions,
} from '../utils/amr-pipeline-filter.utils';
import { mergeSortCriteria } from '../utils/amr-pipeline.utils';
import { amrArrangerPipelineService } from '../services/amr-arranger-pipeline.service';
import { ArrangerPipeline, Category } from '../types/amr-pipeline/models/ArrangerPipeline';
import { RequestState } from '../constants/request-state';
import {
    arrangerPipelineDealsSelector,
    issuanceMonitorFilterSelector,
} from '../selectors/amr-pipeline.selector';
import { errorActions, gridActions } from '../actions';
import { Deal } from '../types/amr-pipeline/models/Deal';
import { Company } from '../types/amr-pipeline/models/Company';
import { GridDataItem } from '../types/state/GridState';
import { ArrangerPipelineDealsState } from '../types/state/ArrangerPipelineDealsState';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { FilterSelectGroup } from '../types/filters/FilterGroup';
import { compareStrings } from '../utils/compare.utils';
import { user } from '../user';
import { LookupDataItem } from '../components/grid/Cells/Lookup2';
import { store } from '../store';
import { arrangers, collateralManagers, dealsLegalNames } from '../utils/filtering/serializers/serializer.definitions';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import { queryStringSerializer } from '../utils/filtering';
import { createFilterActions } from '../actions/filter.actions';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { ArrangerPipelineStatistic } from '../types/amr-pipeline/models/ArrangerPipelineStatistic';
import { history } from '../history';
import { amrCompaniesService } from '../services/amr-companies.service';
import { HavingDealType } from '../types/amr-pipeline/enums/HavingDealType';
import { dealsService } from '../services/deals.service';

const filterActions = createFilterActions(PipelineType.ArrangerPipeline);

function convertGridDataItemToArrangerPipeline(gridDataItem: GridDataItem<ArrangerPipeline>) {
    const {
        referenceName,
        dealReferenceName,
        collateralManagerReferenceName,
        arrangerReferenceName,
        dealLegalName,
        collateralType,
        type,
        nonCallPeriodEnd,
        reinvestmentPeriodEnd,
        expectedTiming,
        dealStage,
        euCompliance,
        anchor,
        equity,
        disclosureFile,
    } = gridDataItem;

    return {
        referenceName,
        dealReferenceName,
        collateralManagerReferenceName,
        arrangerReferenceName,
        dealLegalName,
        type: type || null,
        collateralType: collateralType || null,
        nonCallPeriodEnd,
        reinvestmentPeriodEnd,
        expectedTiming,
        dealStage: dealStage || null,
        euCompliance: euCompliance || null,
        anchor: anchor || null,
        equity: equity || null,
        disclosureFileReferenceName: disclosureFile ? disclosureFile.referenceName : null,
    };
}

function getFilterCriteria(filter: ArrangerPipelineFilters) {
    return {
        collateralManagerReferenceNames: selectedSelectGroupOptions(filter.managers),
        arrangerReferenceNames: selectedSelectGroupOptions(filter.arrangers),
        collateralTypes: selectedSelectGroupOptions(filter.collateralType),
        euCompliance: selectedBooleanOption(filter.euCompliance),
        anchor: selectedBooleanOption(filter.anchor),
        reinvestmentPeriodEnd: selectedRangeOptions(filter.riEnd),
        nonCallPeriodEnd: selectedRangeOptions(filter.ncEnd),
        equities: selectedRadioOption(filter.equity),
    };
}

function filterExistingOptions(optionsNames: string[], optionFilter: FilterSelectGroup) {
    return optionsNames.filter(o => optionFilter.filter.find(f => f.value === o));
}

function* setFilterFromSearchString(filter: ArrangerPipelineFilters) {
    const queryString = history.location.search;

    if (!queryString) {
        return;
    }

    let collateralManagerReferenceNames: string[] = [];
    let arrangerReferenceNames: string[] = [];
    let dealLegalNames: string[] = [];

    const collateralManagersSerializer = collateralManagers(
        collateralManagers =>
            (collateralManagerReferenceNames = filterExistingOptions(collateralManagers, filter.managers)),
        filter.managers.filter.map(filter => filter.value as string),
    );

    const arrangersSerializer = arrangers(
        arrangers => (arrangerReferenceNames = filterExistingOptions(arrangers, filter.arrangers)),
        filter.arrangers.filter.map(filter => filter.value as string),
    );

    const serializers: QueryStringArgumentSerializer<any>[] = [
        collateralManagersSerializer,
        arrangersSerializer,
        dealsLegalNames(legalNames => (dealLegalNames = legalNames ?? [])),
    ];

    queryStringSerializer.deserialize(queryString, serializers);

    if (collateralManagerReferenceNames.length || arrangerReferenceNames.length || dealLegalNames.length) {
        yield put(filterActions.resetFiltersAndUnselectSavedFilter());
        yield put(filterActions.resetFiltersVisibility());
    }

    function* selectOptionFilter(referenceName: string, filterName: string) {
        const selectOptionFilter: Action<any> = yield call(filterActions.filterSelectChange, referenceName, filterName);
        yield put(selectOptionFilter);
    }

    yield all(collateralManagerReferenceNames.map(manager => selectOptionFilter(manager, 'managers')));
    yield all(arrangerReferenceNames.map(arranger => selectOptionFilter(arranger, 'arrangers')));
    yield put(amrArrangerPipelineActions.selectDealsFromSearch(dealLegalNames));

    history.replace(history.location.pathname);
}

function* checkIfArrangerPipelineExist() {
    try {
        const onePipeline: ArrangerPipeline[] = yield call(
            amrArrangerPipelineService.getArrangerPipelines,
            { count: 1 },
            [],
        );

        const anyPipelineExists = !!onePipeline.length;
        yield put(amrArrangerPipelineActions.setAnyPipelineExists(anyPipelineExists));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchArrangerPipelineRequest() {
    const {
        deals: { sortBy, sortDirection, selectedDealsLegalNames, offset },
        filter: { lastAppliedFilter },
    }: ArrangerPipelineState = yield select((state: AppState) => state.issuanceMonitor.amrArrangerPipeline);

    const filterCriteria = {
        ...getFilterCriteria(lastAppliedFilter),
        deals: selectedDealsLegalNames,
        count: constants.defaultTransactionsSearchCriteria.count,
        offset,
    };

    const sortCriteria = mergeSortCriteria(sortBy, sortDirection, constants.defaultArrangerPipelineOrderCriteria);

    try {
        const pipelines: ArrangerPipeline[] = yield call(
            amrArrangerPipelineService.getArrangerPipelines,
            filterCriteria,
            sortCriteria,
        );

        let nonVisiblePipelinesCount: number | undefined;

        if (!user.hasFeatures(SubscriptionFeature.IssuanceMonitorFullAccess)) {
            nonVisiblePipelinesCount = yield call(amrArrangerPipelineService.getArrangerPipelinesCount, filterCriteria);
        }

        const arrangerPipelineDeals: Partial<Deal>[] = yield call(amrArrangerPipelineService.getDealsLegalNames);

        if (!pipelines.length) {
            // check if some pipeline exist for current user
            yield call(checkIfArrangerPipelineExist);
        }

        yield put(amrArrangerPipelineActions.dealsSearchResponse(RequestState.success, arrangerPipelineDeals));
        yield put(
            amrArrangerPipelineActions.arrangerPipelinesResponse(
                RequestState.success,
                pipelines,
                nonVisiblePipelinesCount,
            ),
        );
    } catch (error) {
        yield put(amrArrangerPipelineActions.arrangerPipelinesResponse(RequestState.failure));
    } finally {
        yield put(amrArrangerPipelineActions.setEditLoadingStatus(false));
    }
}

function* watchExport() {
    const {
        deals: { sortBy, sortDirection, selectedDealsLegalNames },
        filter: { lastAppliedFilter },
    }: ArrangerPipelineState = yield select((state: AppState) => state.issuanceMonitor.amrArrangerPipeline);

    const filterCriteria = {
        ...getFilterCriteria(lastAppliedFilter),
        deals: selectedDealsLegalNames,
        count: constants.defaultTransactionsSearchCriteria.count,
    };
    const sortCriteria = mergeSortCriteria(sortBy, sortDirection, constants.defaultArrangerPipelineOrderCriteria);

    yield put(amrArrangerPipelineActions.exportLoad(true));

    try {
        const file: { blob: Blob } = yield call(
            amrArrangerPipelineService.exportArrangerPipelines,
            filterCriteria,
            sortCriteria,
        );
        saveAs(file.blob, `Arranger Pipeline - ${moment().format(constants.issuanceMonitorExportDateFormat)}.xlsx`);
    } catch (error) {
        yield put(errorActions.error(error));
    } finally {
        yield put(amrArrangerPipelineActions.exportLoad(false));
    }
}

function* watchDealsRequest(action: ActionType<typeof amrArrangerPipelineActions.dealsRequest>) {
    try {
        const deals: Deal[] = yield call(dealsService.getDealsList, action.payload.searchTerm);

        yield put(amrArrangerPipelineActions.changeDealReferenceName(null));
        yield put(amrArrangerPipelineActions.dealsResponse(RequestState.success, deals));
    } catch (error) {
        yield put(amrArrangerPipelineActions.dealsResponse(RequestState.failure));
    }
}

function* watchApplyFilterAndSearch() {
    yield put(amrArrangerPipelineActions.resetPipelines());
    yield put(filterActions.applyFilter());

    try {
        yield put(amrArrangerPipelineActions.arrangerPipelinesRequest());
        yield put(amrArrangerPipelineActions.arrangerPipelineStatisticRequest());
    } catch (e) {
        yield put(filterActions.resetFilter());
    }
}

function* watchSummaryRequest() {
    const {
        deals: { selectedDealsLegalNames },
        filter: { lastAppliedFilter },
    }: ArrangerPipelineState = yield select((state: AppState) => state.issuanceMonitor.amrArrangerPipeline);

    const filterCriteria = {
        ...getFilterCriteria(lastAppliedFilter),
        deals: selectedDealsLegalNames,
    };

    try {
        const summary: ArrangerPipelineStatistic = yield call(
            amrArrangerPipelineService.getArrangerPipelinesStatistic,
            filterCriteria,
        );

        yield put(amrArrangerPipelineActions.arrangerPipelineStatisticResponse(summary));
    } catch (e) {
        yield put(errorActions.error(e));
    }
}

function* watchInitFilter() {
    const pipelineType = PipelineType.ArrangerPipeline;
    const filterSelector = issuanceMonitorFilterSelector(pipelineType);
    const imFilterState: PipelineFilterState<typeof pipelineType> = yield select(filterSelector);

    const { filter, initialFilter } = imFilterState;

    if (!initialFilter) {
        if (!filter.managers.filter.length) {
            yield put(
                filterActions.storeFilterData.request({
                    filterType: PipelineType.ArrangerPipeline,
                    filterName: 'managers',
                }),
            );
            yield put(
                filterActions.storeFilterData.request({
                    filterType: PipelineType.ArrangerPipeline,
                    filterName: 'arrangers',
                }),
            );

            const managers: Company[] = yield call(
                amrCompaniesService.getManagersList,
                HavingDealType.CmHavingAllPublishedDeals,
            );
            const arrangers: Company[] = yield call(amrCompaniesService.getArrangersList, true);

            yield put(
                filterActions.storeFilterData.success({
                    filterType: PipelineType.ArrangerPipeline,
                    filterName: 'managers',
                    data: managers
                        .sort((a, b) => a.legalName.localeCompare(b.legalName))
                        .map(m => ({
                            selected: false,
                            disabled: false,
                            visible: true,
                            value: m.referenceName,
                            text: m.legalName,
                        })),
                }),
            );
            yield put(
                filterActions.storeFilterData.success({
                    filterType: PipelineType.ArrangerPipeline,
                    filterName: 'arrangers',
                    data: arrangers
                        .sort((a, b) => a.legalName.localeCompare(b.legalName))
                        .map(a => ({
                            selected: false,
                            disabled: false,
                            visible: true,
                            value: a.referenceName,
                            text: a.legalName + (a.code ? ' (' + a.code + ')' : ''),
                        })),
                }),
            );
        }

        yield initFilter();
    }
}

function* initFilter() {
    const pipelineType = PipelineType.ArrangerPipeline;
    const filterSelector = issuanceMonitorFilterSelector(pipelineType);

    const imFilterState: PipelineFilterState<typeof pipelineType> = yield select(filterSelector);
    const { filter } = imFilterState;

    yield put(filterActions.init(filter));

    const hasImFiltersAccess = user.hasFeatures(SubscriptionFeature.IssuanceMonitorFiltersEmailAlerts);

    if (hasImFiltersAccess) {
        const defaultFilter: UserConfigFilter = yield select((state: AppState) =>
            state.imUserConfig.filtersConfig.ApFilter.value.find(filter => filter.default),
        );

        yield put(filterActions.selectFilterFromConfig(defaultFilter));
        yield setFilterFromSearchString(filter);
    }

    yield call(watchApplyFilterAndSearch);
}

function* watchResetFilter() {
    const resetFiltersAndUnselectSavedFilter: Action<any> = yield call(
        filterActions.resetFiltersAndUnselectSavedFilter,
    );

    yield put(resetFiltersAndUnselectSavedFilter);
    yield call(watchApplyFilterAndSearch);
}

function* watchEdit() {
    const userCompany: Company = yield select((state: AppState) => state.issuanceMonitor.amrPipelineCommon.userCompany);

    const isBD = user.hasRoles(...roles.bd());

    yield put(amrArrangerPipelineActions.resetPipelines());

    try {
        const data: ArrangerPipeline[] = yield call(
            amrArrangerPipelineService.getArrangerPipelines,
            { count: constants.gridRowsLimit, category: Category.ArrangerPipeline },
            constants.defaultArrangerPipelineOrderCriteria,
        );

        const pipelines = data.filter(i => i.category === Category.ArrangerPipeline);

        yield put(amrArrangerPipelineActions.arrangerPipelinesResponse(RequestState.success, pipelines));

        const dataItems =
            pipelines.map(p => ({
                ...p,
                arrangerReferenceName: p.arranger?.referenceName,
                collateralManagerReferenceName: p.collateralManager.referenceName,
            })) ?? [];

        yield put(amrArrangerPipelineActions.submitArrangerPipelineStatus(false));
        yield put(gridActions.reset());

        const managers: Company[] = yield call(amrCompaniesService.getManagersList);
        const arrangers: Company[] = !isBD ? yield call(amrCompaniesService.getArrangersList) : [userCompany];

        const managerColumn = {
            ...gridColumns.collateralManager,
            required: true,
            selectSourceItemsCallback: () => {
                return managers
                    .map(m => ({ key: m.referenceName, title: m.legalName }))
                    .sort((a, b) => compareStrings(a.title, b.title));
            },
        };

        const arrangerGridColumn = {
            ...gridColumns.arranger,
            required: !user.hasRoles(roles.Administrator, roles.DataEntry),
        };

        const arrangerColumn = {
            ...arrangerGridColumn,
            selectSourceItemsCallback: () => {
                return arrangers
                    .map(m => ({ key: m.referenceName, title: m.legalName || m.name }))
                    .sort((a, b) => compareStrings(a.title, b.title));
            },
        };

        const dealNameColumn = {
            ...gridColumns.dealName,
            type: 'lookup',
            lookup: {
                compareCallback: (searchTerm: string, lookupItem: LookupDataItem) => {
                    const deal: Deal = lookupItem.payload;
                    return deal.legalName.toLowerCase().startsWith(searchTerm.toLowerCase());
                },
                fetchCallback: async (searchTerm: string, abortSignal: AbortSignal) => {
                    store.dispatch(amrArrangerPipelineActions.changeDealReferenceName(null));

                    const deals: Deal[] = await dealsService.getDealsNamesList(searchTerm, 'legalName', true);

                    return deals.map(d => ({
                        text: d.legalName,
                        value: d.referenceName,
                        payload: d,
                    }));
                },
                onLookupItemSelected: (deal: LookupDataItem) => {
                    store.dispatch(amrArrangerPipelineActions.changeDealReferenceName(deal.value as string));
                    store.dispatch(amrArrangerPipelineActions.changeDealLegalName(deal.text));
                    store.dispatch(
                        amrArrangerPipelineActions.updateFields(
                            deal.payload.collateralManagerReferenceName,
                            deal.payload.collateralType,
                        ),
                    );
                },
            },
        };

        const columns = gridColumns.arrangerPipeline().map((c: any) => {
            if (c.name === gridColumns.dealName.name) {
                return dealNameColumn;
            }

            if (c.name === gridColumns.collateralManager.name) {
                return managerColumn;
            }

            if (c.name === gridColumns.arranger.name) {
                return arrangerColumn;
            }

            return c;
        });

        const gridInit: Action<any> = yield call(gridActions.init, dataItems, columns, constants.gridRowsLimit);
        const gridValidate: Action<any> = yield call(gridActions.validate);

        yield put(gridInit);
        yield put(gridValidate);
    } catch (e) {
        yield put(errorActions.criticalError(e));
    } finally {
        yield put(amrArrangerPipelineActions.setEditLoadingStatus(false));
    }
}

function* watchDeleteArrangerPipeline(action: ActionType<typeof amrArrangerPipelineActions.deleteArrangerPipeline>) {
    try {
        yield call(amrArrangerPipelineService.deleteArrangerPipeline, action.payload.referenceName);
        yield call(watchApplyFilterAndSearch);
    } catch (error) {
        yield put(errorActions.error(error));
    }
}

function* watchSubmit() {
    const gridValidate: Action<any> = yield call(gridActions.validate);

    yield put(gridValidate);

    const arrangerPipelineState: ArrangerPipelineDealsState = yield select(arrangerPipelineDealsSelector);
    const dataItems = arrangerPipelineState.arrangerPipelines;
    const gridDataItems: GridDataItem<ArrangerPipeline>[] = yield select((state: AppState) => state.grid.dataItems);
    const arrangerPipelines = gridDataItems.filter(g => !g.draft);
    const isGridValid: boolean = yield select((state: AppState) => state.grid.isValid);

    if (!isGridValid && arrangerPipelines.length) {
        yield put(amrArrangerPipelineActions.submitArrangerPipelineStatus(false));
        return;
    }

    const deletedPipelines =
        dataItems?.reduce((acc: Partial<ArrangerPipeline>[], pipeline: ArrangerPipeline) => {
            const isSomePipelineDeleted = !arrangerPipelines.some(
                p => !p.draft && p.referenceName === pipeline.referenceName,
            );

            if (isSomePipelineDeleted) {
                return [
                    ...acc,
                    {
                        referenceName: pipeline.referenceName,
                        arrangerReferenceName: pipeline.arranger?.referenceName,
                        removed: true,
                    },
                ];
            }

            return acc;
        }, []) ?? [];

    try {
        const arrangerPipeline = arrangerPipelines.reduce(
            (acc: Partial<ArrangerPipeline>[], pipeline: GridDataItem<ArrangerPipeline>) => {
                if (pipeline.draft) {
                    return [...acc];
                }

                const pipelineEditItem = convertGridDataItemToArrangerPipeline(pipeline);

                return [
                    ...acc,
                    pipeline.referenceName ? { ...pipelineEditItem } : { ...pipelineEditItem, added: true },
                ];
            },
            [],
        );

        yield call(amrArrangerPipelineService.createOrUpdateArrangerPipeline, [
            ...arrangerPipeline,
            ...deletedPipelines,
        ]);

        yield put(amrArrangerPipelineActions.submitArrangerPipelineStatus(true));
    } catch (error) {
        yield put(errorActions.error(error));
    }
}

function* watchChangeDealReferenceName(action: ActionType<typeof amrArrangerPipelineActions.changeDealReferenceName>) {
    const { position, dataItems } = yield select((state: AppState) => state.grid);

    const editingPipeline = dataItems[position.index];

    const insertDataItems: Action<any> = yield call(
        gridActions.replaceDataItem,
        { ...editingPipeline, dealReferenceName: action.payload.dealReferenceName },
        position.index,
    );

    yield put(insertDataItems);
}

function* watchUpdateFields(action: ActionType<typeof amrArrangerPipelineActions.updateFields>) {
    const { position, dataItems } = yield select((state: AppState) => state.grid);

    const editingPipeline = dataItems[position.index];

    const { collateralManagerReferenceName, collateralType } = action.payload;

    const insertDataItems: Action<any> = yield call(
        gridActions.replaceDataItem,
        { ...editingPipeline, collateralManagerReferenceName, collateralType },
        position.index,
    );

    yield put(insertDataItems);
}

function* watchChangeDealLegalName(action: ActionType<typeof amrArrangerPipelineActions.changeDealLegalName>) {
    const editingGrid: Action<any> = yield call(gridActions.editing, action.payload.dealLegalName);
    const applyEdit: Action<any> = yield call(gridActions.applyOrCancelEdit);

    yield put(editingGrid);
    yield put(applyEdit);
}

export function* watchArrangerPipelineSaga() {
    yield takeLatest(getType(amrArrangerPipelineActions.arrangerPipelinesRequest), watchArrangerPipelineRequest);
    yield takeLatest(getType(amrArrangerPipelineActions.applyFilterAndSearch), watchApplyFilterAndSearch);
    yield takeLatest(getType(amrArrangerPipelineActions.resetFilter), watchResetFilter);
    yield takeLatest(getType(amrArrangerPipelineActions.initFilter), watchInitFilter);
    yield takeLatest(getType(amrArrangerPipelineActions.edit), watchEdit);
    yield takeLatest(getType(amrArrangerPipelineActions.exportPipelines), watchExport);
    yield takeLatest(getType(amrArrangerPipelineActions.dealsRequest), watchDealsRequest);
    yield takeLatest(getType(amrArrangerPipelineActions.changeDealReferenceName), watchChangeDealReferenceName);
    yield takeLatest(getType(amrArrangerPipelineActions.changeDealLegalName), watchChangeDealLegalName);
    yield takeLatest(getType(amrArrangerPipelineActions.updateFields), watchUpdateFields);
    yield takeLatest(getType(amrArrangerPipelineActions.submitArrangerPipeline), watchSubmit);
    yield takeLatest(getType(amrArrangerPipelineActions.checkIfArrangerPipelineExist), checkIfArrangerPipelineExist);
    yield takeLatest(getType(amrArrangerPipelineActions.arrangerPipelineStatisticRequest), watchSummaryRequest);
    yield takeLatest(getType(amrArrangerPipelineActions.deleteArrangerPipeline), watchDeleteArrangerPipeline);
}
