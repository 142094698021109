import { LevelType } from "./LevelType";
import { ColorType } from "./ColorType";
import { LevelSpecificationType } from "../enums/LevelSpecificationType";

export interface Color {
    levelType: LevelType;
    colorType: ColorType;
    levelSpecificationType: LevelSpecificationType;
    traded: boolean;
    level: string;
    normalizedLevel?: number;
    dm?: number;
}

export enum ColorFilterType {
    None = 0,
    DNT = 1,
    Traded = 2,
    TradedColor = 3
}