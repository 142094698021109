import cn from 'classnames';
import IconSVG from '../../styles/svg-icons';
import { SubscriptionFeature } from '../../types/billing/SubscriptionFeature';
import { bwicService } from '../../services';
import { Preloader } from '../common';
import { useDownloadDocument } from '../../effects/useDownloadDocument';

interface Props {
    identifier: string;
    textVisible?: boolean;
    text?: string;
    requiredFeature?: SubscriptionFeature;
    disabled?: boolean;
    fileNameForSave?: string
}

export function DownloadDocuments({ identifier, textVisible = true, disabled, text = 'Docs'  }: Props) {
    const { handleDownload, isDownloading } = useDownloadDocument({
        downloadRequest: bwicService.getSecurityDocsZip,
        requestArgs: [identifier]
    });

    return (
        <button
            className={cn("btn-link link-download-docs", { "btn-is-loading": isDownloading })}
            type="button"
            disabled={disabled}
            onClick={handleDownload}
        >
            <Preloader small fullScreen={false} inProgress={isDownloading} text="Downloading...">
                <IconSVG name="downloadTemplate" width={16} height={16} />
                {textVisible && <span>{text}</span>}
            </Preloader>
        </button>
    );
}
