export enum DocumentType {
    otherDocument = 'OtherDocument',
    otherDealDocument = 'OtherDealDocument',
    indenture = 'Indenture',
    collateralManagementAgreement = 'CollateralManagementAgreement',
    collateralAdministrationAgreement = 'CollateralAdministrationAgreement',
    offeringMemorandum = 'OfferingMemorandum',
    distributionReport = 'DistributionReport',
    monthlyReport = 'MonthlyReport',
    reportsExcelFiles = 'ReportsExcelFiles',
    cleansingNoticeRefinancing = 'CleansingNoticeRefinancing',
    cleansingNoticeReset = 'CleansingNoticeReset',
}

export interface DocumentTypeSelect {
    text: string;
    type: DocumentType;
}

export function getDocumentTypeText(documentType: DocumentType): string {
    switch (documentType) {
        case DocumentType.reportsExcelFiles:
            return 'Reports Excel Files';
        default: return (
            documentType
                .toString()
                .split(/(?=[A-Z])/)
                .join(' ') + 's'
        );
    }

}

export function getDealRegularDocumentTypes(): DocumentType[] {
    return [
        DocumentType.indenture,
        DocumentType.collateralManagementAgreement,
        DocumentType.collateralAdministrationAgreement,
        DocumentType.offeringMemorandum,
        DocumentType.otherDealDocument
    ];
}

export const dealRegularDocsTypeList: DocumentTypeSelect[] = [
    { type: DocumentType.indenture, text: "Indenture" },
    { type: DocumentType.collateralAdministrationAgreement, text: "Administration Agreement" },
    { type: DocumentType.collateralManagementAgreement, text: "Management Agreement" },
    { type: DocumentType.offeringMemorandum, text: "Memorandum" },
    { type: DocumentType.otherDealDocument, text: "Other" },
];

export const cleansingNoticeDocsTypeList: DocumentTypeSelect[]  = [
    { type: DocumentType.cleansingNoticeRefinancing, text: "Refinancing" },
    { type: DocumentType.cleansingNoticeReset, text: "Reset" },
];
