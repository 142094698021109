import { useState } from "react";
import { TabItem, TabList } from "../../bidding/common/tab-list";
import { Popup, PopupBody, PopupFooter } from "../../controls";
import { DownloadDocuments } from "../DownloadDocuments";
import { useDealDocuments } from "../useDealDocuments";
import { isRequestSuccess } from "../../../utils";
import { EmptyPlaceholder, Preloader } from "../../common";
import { ListBuilder } from "../../../utils/ListBuilder";
import { Document, DocumentStoreType } from "../../../types/document/Document";
import { DocumentList } from "../DocumentList";
import { roles } from "../../../constants";
import { getDealDocLastDate, isCollateralManagersOwnCompany } from "../../../utils/amr-pipeline.utils";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { RoleAndFeatureBlocker } from "../../access/RoleAndFeatureBlocker";
import { useImUserCompany } from "../../../effects/useImUserCompany";

export enum DealDocumentsPopupTab {
    OmAndIndenture = "OmAndIndenture",
    CleansingNotice = "CleansingNotice",
    DistributionReports = "DistributionReports",
    MonthlyReports = "MonthlyReports",
    Other = "Other"
}

const allowedRoles = roles.getAllRolesExclude(roles.Media, roles.Viewer);

interface Props {
    security: {
        securityId: number
        isinCusip?: string
        ticker: string
    }
    requiredFeature: SubscriptionFeature | null
    defaultTab?: DealDocumentsPopupTab
    onClose: () => void
}

export function DealDocumentsPopup({
    security,
    onClose,
    requiredFeature,
    defaultTab = DealDocumentsPopupTab.OmAndIndenture
}: Props) {
    const [activeTab, setActiveTab] = useState(defaultTab);
    const { dealDocuments, requestState } = useDealDocuments(security.securityId);
    const { imUserCompany, imUserCompanyRequestState } = useImUserCompany();

    const isLoading = !isRequestSuccess(requestState) || !isRequestSuccess(imUserCompanyRequestState);

    const withCollateralAccess =
        dealDocuments?.collateralManagerReferenceName && imUserCompany
            ? isCollateralManagersOwnCompany(
                {
                    referenceName:
                        dealDocuments.collateralManagerReferenceName,
                },
                imUserCompany
            )
            : false;

    const getOmAndIndentureDocumentsCounter = () =>
        (dealDocuments?.om?.length ?? 0) + (dealDocuments?.indenture?.length ?? 0)

    const getActiveTabDocuments = (): Document[] => {
        switch (activeTab) {
            case DealDocumentsPopupTab.OmAndIndenture:
                return new ListBuilder<Document>()
                    .addWhen(() => !!dealDocuments?.om?.length, ...dealDocuments?.om ?? [])
                    .addWhen(() => !!dealDocuments?.indenture?.length, ...dealDocuments?.indenture ?? [])
                    .result()
            case DealDocumentsPopupTab.CleansingNotice: return dealDocuments?.cleansingNoticeDocuments ?? []
            case DealDocumentsPopupTab.DistributionReports: return dealDocuments?.distributionReports ?? []
            case DealDocumentsPopupTab.MonthlyReports: return dealDocuments?.monthlyReports ?? []
            default: return dealDocuments?.other ?? []
        }
    }

    const activeTabDocuments = getActiveTabDocuments()
        .map(d => ({ ...d, executionDate: getDealDocLastDate(d) }));

    const downloadRequiredFeature = !requiredFeature || withCollateralAccess ? [] : [requiredFeature];

    return (
        <Popup
            renderInBody
            modalClass="documents-popup"
            title={
                <>
                    Documents <span className="name-tag">{security.ticker}</span>
                    <div className="flex-item-right documents-popup-download-documents">
                        <RoleAndFeatureBlocker requiredFeatures={downloadRequiredFeature} requiredRoles={allowedRoles}>
                            {blocked => (
                                <>
                                    <DownloadDocuments
                                        identifier={security.isinCusip || security.ticker}
                                        disabled={blocked || isLoading}
                                        text="Download All"
                                        fileNameForSave={security.ticker}
                                    />
                                </>
                            )}
                        </RoleAndFeatureBlocker>
                    </div>
                </>
            }
            onClose={onClose}
        >
            <PopupBody>
                <Preloader inProgress={isLoading}>
                    <TabList>
                        <TabItem
                            title="OM & Indenture"
                            tooltip="Offering Memorandum and Indenture documents"
                            active={activeTab === DealDocumentsPopupTab.OmAndIndenture}
                            counter={getOmAndIndentureDocumentsCounter()}
                            onClick={() => setActiveTab(DealDocumentsPopupTab.OmAndIndenture)}
                        />
                        <TabItem
                            title="Cleansing Notice"
                            tooltip="Cleansing Notice"
                            active={activeTab === DealDocumentsPopupTab.CleansingNotice}
                            counter={dealDocuments?.cleansingNoticeDocuments?.length}
                            onClick={() => setActiveTab(DealDocumentsPopupTab.CleansingNotice)}
                        />
                        <TabItem
                            title="Distribution Reports"
                            tooltip="Distribution Reports"
                            active={activeTab === DealDocumentsPopupTab.DistributionReports}
                            counter={dealDocuments?.distributionReports?.length}
                            onClick={() => setActiveTab(DealDocumentsPopupTab.DistributionReports)}
                        />
                        <TabItem
                            title="Monthly Reports"
                            tooltip="Monthly Reports"
                            active={activeTab === DealDocumentsPopupTab.MonthlyReports}
                            counter={dealDocuments?.monthlyReports?.length}
                            onClick={() => setActiveTab(DealDocumentsPopupTab.MonthlyReports)}
                        />
                        <TabItem
                            title="Other"
                            tooltip="Other Deal documents"
                            active={activeTab === DealDocumentsPopupTab.Other}
                            counter={dealDocuments?.other?.length}
                            onClick={() => setActiveTab(DealDocumentsPopupTab.Other)}
                        />
                    </TabList>
                    {activeTabDocuments.length ? (
                        <DocumentList
                            key={activeTab}
                            readonly
                            documents={activeTabDocuments}
                            documentStoreType={DocumentStoreType.Default}
                            headerVisible={false}
                            downloadRequiredFeatures={downloadRequiredFeature}
                            downloadRequiredRoles={allowedRoles}
                        />
                    ) : (
                        <EmptyPlaceholder text="There are no documents yet." />
                    )}
                </Preloader>
            </PopupBody>
            <PopupFooter>
                <button className="btn btn-ghost" onClick={onClose}>
                    Close
                </button>
            </PopupFooter>
        </Popup>
    );
}
