import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { manageEmailPreferencesActions } from '../../../../../../actions/manage-email-preferences.actions';
import { routes } from '../../../../../../constants/routes';
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertEnabledState } from '../../../../../../types/enums/AlertEnabledState';
import { StatusMessageSectionType } from '../../../../../../types/state/NotificationState';
import { isRequesting } from '../../../../../../utils';
import { RouteLeavingGuard } from '../../../../../routing';
import { StatusMessageSection } from '../../../../../status-message/StatusMessageSection';
import { SavePortfolioAlertsButton } from './SavePortfolioAlertsButton';
import { PageConfigType } from '../../../../../../types/page-config/PageConfigType';
import { BwicFilterType } from '../../../../../../types/filters/FilterState';
import { usePageConfig } from '../../../../../common/pageConfig/usePageConfig';
import { PortfolioUserConfigFilter } from '../../../../../../types/user-config/UserConfigFilter';
import { usePortfolioAlertsChangedState } from './usePortfolioAlertsChangedState';
import { UnsubscribePortfolioAlertsButton } from './UnsubscribePortfolioAlertsButton';
import { AlertOption, instantAlertOptions, periodicAlertOption } from '../../../../../../types/email-preferences/EmailPreferences';
import { emailPreferencesUtils } from '../../../../../../utils/email-preferences.utils';
import { PortfolioFiltersAlertsTable } from './PortfolioFilterAlertsTable';
import { EmailPreferencesRadioGroup } from '../EmailPreferencesRadioGroup';
import { textLabels } from '../../../../../../constants/text-labels';
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';

export const portfolioImRequiredFeatures = [
    SubscriptionFeature.IssuanceMonitorFullAccess,
    SubscriptionFeature.PortfolioAlerts,
];

export function PortfolioTab() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { config, requestState, updateRequestState } = usePageConfig(
        PageConfigType.Portfolio,
        true,
        BwicFilterType.Portfolio,
    );
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);


    const portfolioCleansingNoticeAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioCleansingNoticeAlertState,
    );
    const portfolioIssuanceMonitorAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState,
    );
    const portfolioRiAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioOutOfRiPeriodAlertState,
    );
    const portfolioNcAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioOutOfNcPeriodAlertState,
    );
    const portfolioRollerDeadlineAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState,
    );
    const allSecuritiesBwicAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState,
    );
    const allSecuritiesInventortAlertState = useAppSelector(
        s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState,
    );

    const cleansingNoticeAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioCleansingNoticeAlertState,
        AlertOption.Instant,
    );
    const issuanceMonitorAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioIssuanceMonitorAlertState,
        AlertOption.Instant,
    );
    const riAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioRiAlertState,
        AlertOption.Periodic,
    );
    const ncAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioNcAlertState,
        AlertOption.Periodic,
    );
    const rollerDeadlineAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        portfolioRollerDeadlineAlertState,
        AlertOption.Instant,
    );

    const allSecuritiesBwicAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesBwicAlertState,
        AlertOption.Instant,
    );
    const allSecuritiesInventoryAlertOption = emailPreferencesUtils.convertAlertEnabledStateToAlertOption(
        allSecuritiesInventortAlertState,
        AlertOption.Instant,
    );

    const hasChanges = usePortfolioAlertsChangedState();

    const handleChangeImAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioIssuanceMonitorAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeRiAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRiAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeNcAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioNcAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleChangeRollerDeadlineAlert = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioRollerDeadlineAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleBwicAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioBwicAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleCleansingNoticeAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioCleansingNoticeAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const handleInventoryAlertChange = (value: AlertOption) => {
        dispatch(
            manageEmailPreferencesActions.setPortfolioInventoryAlerts(
                emailPreferencesUtils.convertAlertOptionToAlertEnabledState(value),
            ),
        );
    };

    const withPartialCheck = [
        portfolioCleansingNoticeAlertState,
        portfolioIssuanceMonitorAlertState,
        portfolioRiAlertState,
        portfolioNcAlertState,
        portfolioRollerDeadlineAlertState,
        allSecuritiesBwicAlertState,
        allSecuritiesInventortAlertState,
    ].some((alertState) => alertState === AlertEnabledState.PartiallyEnabled);

    return (
        <>
            <div className="flex-row save-changes">
                {withPartialCheck && (
                    <StatusMessageSection type={StatusMessageSectionType.Info}>
                        Some alerts are disabled on partial check, see <Link to={routes.portfolio}>Portfolio</Link> page
                        for details and management.
                    </StatusMessageSection>
                )}
                <div className="flex-item-right">
                    <UnsubscribePortfolioAlertsButton
                        disabled={isRequesting(requestState, updateRequestState, savePreferencesRequestState)}
                    />
                    <SavePortfolioAlertsButton className="btn-sm" />
                </div>
            </div>
            <div className="controls-wrap">
                <EmailPreferencesRadioGroup
                    value={allSecuritiesBwicAlertOption}
                    alertOptions={instantAlertOptions}
                    alertOptionInfo={{
                        [AlertOption.Instant]:
                            textLabels.savedFiltersPortfolioBwicInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-bwicAlert-alert-state"
                    title="BWIC Alerts"
                    description={
                        textLabels.savedFiltersPortfolioBwicAlertDescription
                    }
                    onChange={handleBwicAlertChange}
                    partially={
                        allSecuritiesBwicAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                />
                <EmailPreferencesRadioGroup
                    value={cleansingNoticeAlertOption}
                    alertOptions={instantAlertOptions}
                    alertOptionInfo={{
                        [AlertOption.Instant]:
                            textLabels.savedFiltersPortfolioCleansingNoticeInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-cleansing-notice-alert-state"
                    title="Cleansing Notice"
                    description={
                        textLabels.savedFiltersPortfolioCleansingNoticeAlertDescription
                    }
                    onChange={handleCleansingNoticeAlertChange}
                    partially={
                        portfolioCleansingNoticeAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                    requiredFeatures={portfolioImRequiredFeatures}
                />
                <EmailPreferencesRadioGroup
                    value={allSecuritiesInventoryAlertOption}
                    alertOptions={instantAlertOptions}
                    alertOptionInfo={{
                        [AlertOption.Instant]:
                            textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                    }}
                    groupNameIndentifier="single-portfolio-inventoryAlert-alert-state"
                    title="Inventory Alerts"
                    description={
                        textLabels.savedFiltersPortfolioInventoryAlertDescription
                    }
                    onChange={handleInventoryAlertChange}
                    partially={
                        allSecuritiesInventortAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                />
                <EmailPreferencesRadioGroup
                    value={issuanceMonitorAlertOption}
                    alertOptions={instantAlertOptions}
                    alertOptionInfo={{
                        [AlertOption.Instant]:
                            textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                    title="Issuance Monitor"
                    description={
                        textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription
                    }
                    onChange={handleChangeImAlert}
                    partially={
                        portfolioIssuanceMonitorAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                    requiredFeatures={portfolioImRequiredFeatures}
                />
                <EmailPreferencesRadioGroup
                    value={riAlertOption}
                    alertOptions={periodicAlertOption}
                    alertOptionInfo={{
                        [AlertOption.Periodic]:
                            textLabels.savedFiltersPortfolioOutOfRiPeriodicAlert,
                    }}
                    groupNameIndentifier="single-portfolio-out-of-ri-alert-state"
                    title="Out of RI Period"
                    description={
                        textLabels.savedFiltersPortfolioOutOfRiAlertDescription
                    }
                    onChange={handleChangeRiAlert}
                    partially={
                        portfolioRiAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                    requiredFeatures={portfolioImRequiredFeatures}
                />
                <EmailPreferencesRadioGroup
                    value={ncAlertOption}
                    alertOptions={periodicAlertOption}
                    alertOptionInfo={{
                        [AlertOption.Periodic]:
                            textLabels.savedFiltersPortfolioOutOfNcPeriodicAlert,
                    }}
                    groupNameIndentifier="single-portfolio-out-of-nc-alert-state"
                    title="Out of NC Period"
                    description={
                        textLabels.savedFiltersPortfolioOutOfNcAlertDescription
                    }
                    onChange={handleChangeNcAlert}
                    partially={
                        portfolioNcAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                    requiredFeatures={portfolioImRequiredFeatures}
                />
                <EmailPreferencesRadioGroup
                    value={rollerDeadlineAlertOption}
                    alertOptions={instantAlertOptions}
                    alertOptionInfo={{
                        [AlertOption.Instant]:
                            textLabels.savedFiltersPortfolioRollerDeadlineInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                    title="Roller Deadline"
                    description={
                        textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription
                    }
                    onChange={handleChangeRollerDeadlineAlert}
                    partially={
                        portfolioRollerDeadlineAlertState ===
                        AlertEnabledState.PartiallyEnabled
                    }
                    requiredFeatures={portfolioImRequiredFeatures}
                />
                <PortfolioFiltersAlertsTable
                    disabled={isRequesting(requestState, updateRequestState, savePreferencesRequestState)}
                    savedFilters={(config?.filters as PortfolioUserConfigFilter[]) ?? []}
                />
                <RouteLeavingGuard
                    navigate={(pathname: string) => {
                        dispatch(manageEmailPreferencesActions.rollbackPortfolio());
                        dispatch(manageEmailPreferencesActions.resetPortfolioFilterAlerts());
                        history.push(pathname);
                    }}
                    shouldBlockNavigation={() => hasChanges}
                />
            </div>
        </>
    );
}
