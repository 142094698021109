import { responseType } from '../constants';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';
import { Equity } from '../types/amr-pipeline/enums/Equity';
import { ArrangerPipeline, Category } from '../types/amr-pipeline/models/ArrangerPipeline';
import { fetchSafe } from './fetch.safe';

interface ArrangerPipelineFilterCriteria {
    collateralManagers: Array<string | number>;
    arrangerReferenceNames: Array<string | number>;
    collateralTypes?: CollateralType[];
    euCompliance?: boolean;
    anchor?: boolean;
    riEnd: { from?: number; to?: number };
    ncEnd: { from?: number; to?: number };
    equity?: Equity[];
    count: number;
    offset: number;
    deals: Array<string>,
    category: Category;
};

type ArrangerPipelineSearchCriteria = Array<{ field: string } | { field: string, ascending: boolean }>


export const amrArrangerPipelineService = {
    getArrangerPipelines,
    getArrangerPipelinesCount,
    getArrangerPipelinesByTicker,
    createOrUpdateArrangerPipeline,
    exportArrangerPipelines,
    getDealsLegalNames,
    downloadDisclosureFile,
    downloadManagerPresentation,
    getArrangerPipelinesStatistic,
    deleteArrangerPipeline,
};

function deleteArrangerPipeline(referenceName: string) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/${referenceName}`, {
        method: 'DELETE',
        responseType: responseType.none,
    });
};

function getArrangerPipelinesStatistic(
    filterCriteria: Partial<ArrangerPipelineFilterCriteria>,
) {

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/statistics`, {
        method: 'POST',
        body: JSON.stringify(filterCriteria),
    });
}


function createOrUpdateArrangerPipeline(arrangerPipelines: Partial<ArrangerPipeline>[]) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines`, {
        method: 'PUT',
        body: JSON.stringify(arrangerPipelines),
    });
}

function getArrangerPipelines(filterCriteria: Partial<ArrangerPipelineFilterCriteria>, searchCriteria: ArrangerPipelineSearchCriteria) {
    const searchParams = {
        orderBy: searchCriteria,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines`, {
        method: 'POST',
        body: JSON.stringify({ ...filterCriteria, ...searchParams }),
    });
}

function getArrangerPipelinesByTicker(tickers: string[]) {
    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/by-tickers`, {
        method: 'POST',
        body: JSON.stringify({ tickers }),
    });
}

function getArrangerPipelinesCount(filterCriteria: Partial<ArrangerPipelineFilterCriteria>) {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/count`, {
        method: 'POST',
        body: JSON.stringify(filterCriteria),
    });
}

function exportArrangerPipelines(filterCriteria: Partial<ArrangerPipelineFilterCriteria>, searchCriteria: ArrangerPipelineSearchCriteria) {
    const searchParams = {
        orderBy: searchCriteria,
    };

    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/export`, {
        method: 'POST',
        responseType: responseType.file,
        body: JSON.stringify({ ...filterCriteria, ...searchParams }),
    });
}

function downloadDisclosureFile(referenceName: string, bankReferenceName: string = '') {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/${referenceName}/disclosure-file/download/${bankReferenceName}`,
        requestOptions,
    );
}

function downloadManagerPresentation(referenceName: string, accessType: string = '') {
    const requestOptions = {
        responseType: responseType.file,
    };

    return fetchSafe(
        `${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/${referenceName}/manager-presentation-file/download/${accessType}`,
        requestOptions,
    );
}

function getDealsLegalNames() {
    return fetchSafe(`${process.env.REACT_APP_AMR_ENDPOINT}/api/bwic/arranger-pipelines/deals`);
}
