import { BwicSavedFilter } from '../../../../types/filters/BwicSavedFilter';
import { FilterOption } from '../../../../types/filters/FilterOption';
import { UserFilterValues } from '../../../../types/user-config/UserConfigFilter';
import { deserializeBooleanFilter, deserializeDateFilter, deserializeRangeFilter, deserializeSelectFilter } from '../amr-pipeline/filterDeserializers';

export const deserializeBwicFilters = (filter: UserFilterValues, stateFilter: BwicSavedFilter) => {
    return {
        date: filter.date && deserializeDateFilter(filter.date),
        size: filter.size && deserializeRangeFilter(filter.size),
        ratings: stateFilter.ratings && deserializeSelectFilter(stateFilter.ratings.filter, filter.ratings),
        statuses: stateFilter.statuses && deserializeSelectFilter(stateFilter.statuses.filter, filter.statuses),
        dealerIds: stateFilter.dealerIds && deserializeSelectFilter(stateFilter.dealerIds.filter, filter.dealerIds),
        managers: stateFilter.managers && deserializeBwicSelectFilter(stateFilter.managers.filter, filter.collateralManagers),
        dealName: stateFilter.dealName && deserializeBwicSelectFilter(stateFilter.dealName.filter, filter.dealName),
        closing: deserializeDateFilter(filter.closingDate),
        maturity: deserializeDateFilter(filter.maturity),
        reinvestmentEnd: deserializeDateFilter(filter.reinvestmentPeriodEnd, filter.reinvestmentPeriodEndDays),
        nonCallEnd: deserializeDateFilter(filter.nonCallPeriodEnd, filter.nonCallPeriodEndDays),
        vintage: deserializeDateFilter(filter.vintage),
        collateralType: deserializeSelectFilter(stateFilter.collateralType.filter, filter.collateralTypes),
        amr: deserializeBooleanFilter(filter.amrDeal),
        currency: stateFilter.currency && deserializeSelectFilter(stateFilter.currency.filter, filter.currencyCodes),
        euCompliance: deserializeBooleanFilter(filter.euCompliance),
        trustees: stateFilter.trustees && deserializeBwicSelectFilter(stateFilter.trustees.filter, filter.trustees),
        esg: deserializeBooleanFilter(filter.esg),
        staticDeal: deserializeBooleanFilter(filter.staticDeal),
        outOfNC: deserializeBooleanFilter(filter.outOfNC),
        outOfRI: deserializeBooleanFilter(filter.outOfRI),
        color: stateFilter.color && deserializeSelectFilter(stateFilter.color.filter, filter.color),
        tradedColor: filter.tradedColor && deserializeRangeFilter(filter.tradedColor),
    };
}

function deserializeBwicSelectFilter<T = string>(
    stateFilterOptions?: FilterOption<T>[],
    savedFilterValues?: T[]): FilterOption<T>[] | undefined {
    if (!savedFilterValues?.length) return undefined;
    if (stateFilterOptions?.length) return deserializeSelectFilter(stateFilterOptions, savedFilterValues);

    return savedFilterValues.map(value => ({
        value,
        text: (value as unknown as string),
        selected: true,
        visible: true,
        disabled: false
    }));
}
