import { useDispatch } from "react-redux";
import { values } from "lodash";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { UnsubscribeAllButton } from "../UnsubscribeAllButton";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";

interface Props {
    disabled?: boolean;
}

export function UnsubscribePortfolioAlertsButton({ disabled }: Props) {
    const dispatch = useDispatch();

    const bwicAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState);
    const cleansingNoticeAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioCleansingNoticeAlertState);
    const invneotryAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState);
    const issuanceMonitorAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioIssuanceMonitorAlertState);
    const outOfRiAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioOutOfRiPeriodAlertState);
    const outOfNcAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioOutOfNcPeriodAlertState);
    const rollerDeadlineAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioRollerDeadlineAlertState);
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);

    const hasEnabledAlerts = () =>
        [
            bwicAlertState,
            cleansingNoticeAlertState,
            invneotryAlertState,
            issuanceMonitorAlertState,
            outOfRiAlertState,
            outOfNcAlertState,
            rollerDeadlineAlertState,
        ].some(
            (alertState) =>
                alertState === AlertEnabledState.Enabled ||
                alertState === AlertEnabledState.PartiallyEnabled
        ) ||
        values(filtersAlertState).some(
            (x) =>
                x.bwicAlert === AlertOption.Instant ||
                x.inventoryAlert === AlertOption.Instant
        );

    return (
        <UnsubscribeAllButton
            onClick={() => dispatch(manageEmailPreferencesActions.unsubscribeAllPortfolio())}
            disabled={disabled || !hasEnabledAlerts()}
        />
    );
}
