import { AlertEnabledState } from '../enums/AlertEnabledState';
import { Rating } from '../enums/Rating';

export enum AlertOption {
    Never = 'Never',
    Daily = 'Daily',
    Instant = 'Instant',
    Periodic = 'Periodic',
}

export interface RatingPreference {
    rating: Rating;
    isEUR: boolean;
    isUSD: boolean;
    minSize: number;
    maxSize: number;
}

export interface BwicByRatingPreferences {
    bwicByRatingAlertState: AlertOption;
    ratingPreference: RatingPreference[];
}

export interface EmailPreferences {
    dailyDashboardEmail: AlertOption;
    ratingPreference: RatingPreference[];
    hasPortfolio: boolean;
    portfolioBwicAlertState: AlertEnabledState;
    portfolioCleansingNoticeAlertState: AlertEnabledState;
    portfolioDealersInventoryAlertState: AlertEnabledState;
    newBwicAlertState: AlertEnabledState;
    portfolioIssuanceMonitorAlertState: AlertEnabledState;
    portfolioOutOfRiPeriodAlertState: AlertEnabledState;
    portfolioOutOfNcPeriodAlertState: AlertEnabledState;
    portfolioRollerDeadlineAlertState: AlertEnabledState;
    bwicReminderAlertState: AlertEnabledState;
    productUpdates: boolean;
    eventsAndOffers: boolean;
    featuredContent: boolean;
    bwicByRatingAlertState: AlertOption;
    dealerInventoryAlertState: AlertOption;
}

export const instantAlertOptions = [AlertOption.Never, AlertOption.Instant];
export const periodicAlertOption = [AlertOption.Never, AlertOption.Periodic];
