import { useState } from "react";
import { useQuery } from "../../effects/useQuery";
import { DealDocumentsPopup, DealDocumentsPopupTab } from "../documents/deal-documents-popup/DealDocumentsPopup";
import { useAppSelector } from "../../effects/useAppSelector";
import { SubscriptionFeature } from "../../types/billing/SubscriptionFeature";

interface Props {
    defaultTab?: DealDocumentsPopupTab
}

export function DealDocumentsAutoOpener({ defaultTab = DealDocumentsPopupTab.CleansingNotice }: Props) {
    const qs = useQuery<{ docs_view?: string }>();
    const dealTicker = qs.docs_view;

    const [visible, setVisible] = useState(true);

    const portfolios = useAppSelector(s => s.entities.portfolios.items);

    if (!dealTicker || !visible) return null;

    const security = portfolios
        .map(p => p.securities)
        .flat()
        .find(s => dealTicker.localeCompare(s.dealTicker ?? '', undefined, { sensitivity: 'accent' }) === 0);

    if (!security) return null;

    return <DealDocumentsPopup
        security={security}
        requiredFeature={SubscriptionFeature.CanAccessDealDocuments}
        defaultTab={defaultTab}
        onClose={() => setVisible(false)}
    />
}